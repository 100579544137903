import React from 'react';
import { ImageListItem, Box, Divider, Drawer, Icon, ListItemButton, ListItemIcon, ListItemText, useTheme, List, useMediaQuery, Typography } from '@mui/material';
import { signOut, useAppThemeContext, useDrawerContext } from '../../contexts';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

interface IComponentSideBar {
  children: React.ReactNode;
}

export const SideBar: React.FC<IComponentSideBar> = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();
  const { toggleTheme } = useAppThemeContext();

  interface IListItemLinkProps {
    to: string;
    icon: string;
    label: string;
    onClick: (() => void);
  }

  const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
    const navigate = useNavigate();

    const resolvedPath = useResolvedPath(to);
    const match = useMatch({ path: resolvedPath.pathname, end: false });

    const handleClick = () => {
      navigate(to);
      onClick?.();
    };

    return (
      <ListItemButton selected={!!match} sx={{ ...(match && { color: 'primary.main', fontWeight: 'bolder' }) }} onClick={handleClick}>
        <ListItemIcon>
          <Typography>
            <Icon>{icon}</Icon>
          </Typography>
        </ListItemIcon>
        <ListItemText primary={label} sx={{ fontWeight: '900' }} />
      </ListItemButton>
    );
  };

  return (
    <>
      <Drawer  open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
        <Box width={theme.spacing(28)} height="100%" display="flex" flexDirection="column">

          <Box width="100%" height={theme.spacing(20)} display="flex" alignItems="center" justifyContent="center">
            <ImageListItem><img src="https://i.ibb.co/zScQ2X1/logo.png" alt="Logo MCare"></img></ImageListItem>
          </Box>

          <Divider />

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map(drawerOption => (
                <ListItemLink icon={drawerOption.icon} label={drawerOption.label} to={drawerOption.path} onClick={toggleDrawerOpen} key={drawerOption.path} />
              ))}
            </List>

            <Divider />

            <List component="nav">
              <ListItemLink icon="group" label="Cadastro dos Médicos" to="/users" onClick={toggleDrawerOpen} key="Cadastro dos Médicos" />
            </List>
            <List component="nav">
              <ListItemLink icon="logout" label="Deslogar" to="/login" onClick={signOut} key="Sair" />
            </List>
          </Box>
          <Box>
            <List component="nav">
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Typography>
                    <Icon>dark_mode</Icon>
                  </Typography>
                </ListItemIcon>
                <ListItemText primary="Modo Dark" />
              </ListItemButton>
            </List>
          </Box>

        </Box>
      </Drawer>
      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>

    </>
  );
};