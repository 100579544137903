import { useEffect, useMemo, useState } from 'react';
import { BarFilterTable } from '../../shared/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { Grid, CircularProgress, Button, Icon } from '@mui/material';
import DataTable, { TableColumn } from 'react-data-table-component';
import { IListPatients, Patients } from '../../shared/services/api/entity/Patients';
import { Environment } from '../../shared/environment';
import { dataTableStyle, noData, paginationComponentOptions } from '../../shared/utils/datatable/style';
import { api } from '../../shared/services';
import { useAppThemeContext } from '../../shared/contexts';
import moment from 'moment';
import { toast } from 'react-toastify';


export const Patient = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListPatients[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const theme = useAppThemeContext();
  const navigate = useNavigate();

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);
  const status = useMemo(() => {
    return searchParams.get('status') || 'all';
  }, [searchParams]);

  const handleSearch = () => {
    Patients.getAll()
      .then((response) => {
        if (response instanceof Error) {
          console.error(response);
          location.reload();
        } else {
          let result = response.data;

          if (result.length) {
            if (search) {
              result = result.filter(el => el.name.toLowerCase().includes(search.toLowerCase()));
            }

            if (status === 'all') {
              result = response.data;
            } else {
              if (status) {
                result = result.filter(el => el.status.toLowerCase().includes(status.toLowerCase()));
              }
            }

            setRows(result);
            setTotalCount(result.length);
          }
        }
      }).catch((error) => {
        console.error(error);
      });
  };
  const columns: TableColumn<IListPatients>[] = [
    {
      name: 'Paciente',
      selector: rows => rows.name
    },
    {
      name: 'Data de Nascimento',
      selector: rows => moment(rows.born_date).format('DD/MM/YYYY')
    },
    {
      name: 'Respondido em',
      selector: rows => moment(rows.date_created).format('DD/MM/YYYY'),
    },
    {
      name: 'Status',
      selector: rows => {
        switch (rows.status) {
        case 'paid':
          return 'Pendente prontuário';
        case 'answered':
          return 'Pendente pagamento';
        case 'pending':
          return 'Pendente pagamento';
        case 'canceled':
          return 'Pagamento cancelado';
        case 'done':
          return 'Prontuário cadastrado';
        default:
          return rows.status;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      name: 'Enviar pesquisa',
      cell: rows =>
        (
          <Button onClick={() => {
            api[1].post('/send-research-to-mobile', { id: rows.id })
              .then(() => {
                toast.success('Pesquisa enviada para o celular.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              })
              .catch(() => {
                toast.error('Erro: a pesquisa não foi enviada!', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          }}>
            <Icon>mobile_screen_share</Icon>
          </Button>
        )
    }
  ];

  useEffect(() => {
    handleSearch();
  }, [search, status]);

  const goToEvolution = (rows: IListPatients) => {
    navigate(`/patient/${rows.id}`);
  };

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress style={{ color: '#02255C' }} />
      <div>{Environment.LOOKING_RECORD}</div>
    </div>
  );

  return (
    <LayoutDefault title="Lista de pesquisas respondidas pelos pacientes">
      <BarFilterTable
        showInputSearch={true}
        textSearchName={search ?? ''}
        changeTextSearchName={text => setSearchParams({ search: text, status: status }, { replace: true })}

        showInputSelect={true}
        textSelected={status ?? 'all'}
        changeInputSelect={text => setSearchParams({ search: search, status: text }, { replace: true })}

        changeButtonNew={() => navigate('/user/new/')}
      />
      <Grid minHeight={400}>
        <DataTable
          columns={columns}
          data={rows}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={totalCount}
          pointerOnHover
          progressComponent={<CustomLoader />}
          theme={theme.themeName === 'light' ? 'light' : 'dark'}
          onRowClicked={(row) => goToEvolution(row)}
          customStyles={dataTableStyle}
          noDataComponent={noData.text}
        />
      </Grid>
    </LayoutDefault >
  );
};