import { createContext, ReactNode, useState, useEffect } from 'react';

import { destroyCookie, setCookie, parseCookies } from 'nookies';
import { Navigate, Route } from 'react-router-dom';
import { api } from '../services';
import { toast } from 'react-toastify';


type AuthContextData = {
  user?: UserProps;
  isAuthenticated: boolean;
  signIn: (credentials: SignInProps) => Promise<void>;
  signOut: () => void;
}

type UserProps = {
  id_token: string;
  refresh_token: string;
  access_token: string;
}

type SignInProps = {
  username: string;
  password: string;
}

type AuthProviderProps = {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);


export function signOut() {
  try {
    destroyCookie(undefined, '@airbnb-Token');
    destroyCookie(undefined, '@airbnb-Refresh-Token');
    destroyCookie(undefined, '@airbnb-Id-Token');

    <Route path='/login' element={<Navigate to="/login" />} />;
    window.location.reload();
  } catch {
    console.log('erro ao deslogar');
  }
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserProps>();
  const isAuthenticated = !!user;

  useEffect(() => {
    const { '@airbnb-Refresh-Token': oldRefreshToken } = parseCookies();

    if (oldRefreshToken) {

      api[0].post('/refresh-token', { refresh_token: oldRefreshToken })
        .then(response => {
          const { access_token, id_token, refresh_token } = response.data.data;

          setUser({
            id_token,
            refresh_token,
            access_token,
          });

          setCookie(undefined, '@airbnb-Token', access_token, {
            maxAge: 60 * 60 * 24 * 30,
            path: '/'
          });

          setCookie(undefined, '@airbnb-Id-Token', id_token, {
            maxAge: 60 * 60 * 24 * 30,
            path: '/'
          });

          <Route path='/emails' element={<Navigate to="/emails" />} />;

        }).catch(() => {
          signOut();
        });
    }
  }, []);

  async function signIn({ username, password }: SignInProps) {
    try {
      const response = await api[0].post('/login', { username, password });

      const { access_token, id_token, refresh_token } = response.data.data;

      setCookie(undefined, '@airbnb-Token', access_token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      });

      setCookie(undefined, '@airbnb-Id-Token', id_token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      });

      setCookie(undefined, '@airbnb-Refresh-Token', refresh_token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      });

      setUser({
        id_token,
        refresh_token,
        access_token,
      });

      api[0].defaults.headers['Authorization'] = `${id_token}`;


      <Route path='/emails' element={<Navigate to="/emails" />} />;
      window.location.reload();

    } catch (err) {
      toast.error('Por favor tente novamente, e-mail ou senha incorretos.');
      console.log('Erro ao autenticar', err);
    }
  }
  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}