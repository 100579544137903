import { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BarFilterTable } from '../../shared/components';
import { LayoutDefault } from '../../shared/layouts';
import { IListUser, UserService } from '../../shared/services/api/entity/UserServices';
import { UseDebounce } from '../../shared/hooks';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { VTextField } from '../../shared/forms';
import { toast } from 'react-toastify';

interface IFormData {
  name: string;
  crm: string;
  phone: string;
}

export const EditUser: React.FC = () => {
  const { username = 'new' } = useParams<'username'>();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<IListUser>();

  const theme = useTheme();
  const navigate = useNavigate();
  const { debounce } = UseDebounce(1000);
  const formRef = useRef<FormHandles>(null);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSave = async (data: IFormData) => {
    const result = {
      username: String(email?.email),
      ...data
    };
    setIsLoading(true);
    await UserService.updateByUsername(result)
      .then((response) => {
        console.log(response);
        if (response instanceof Error) {
          setIsLoading(false);
          toast.success('Erro ao atualizar o Registro.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.log(response.message);
        } else {
          setIsLoading(false);
          toast.success('Registro atualizado com sucesso.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }).catch((err) => {
        setIsLoading(false);
        toast.success('Erro ao atualizar o Registro.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(err);
      });
  };

  const handleDelete = () => {
    console.log('delete');
  };

  useEffect(() => {
    debounce(() => {
      if (username !== 'new') {
        UserService.getAll()
          .then((resAll) => {
            if (resAll instanceof Error) {
              console.log('error');

            } else {
              resAll.data.map((res) => {
                if (res.username === username) {
                  setEmail(res);

                  formRef.current?.setData(res);
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }, []);

  return (
    <LayoutDefault title="Editar Registro">
      <BarFilterTable
        showButtonBack={true}
        showButtonNew={true}
        showButtonSave={true}
        showButtonSaveLoading={isLoading}
        changeButtonBack={() => navigate('/users/')}
        changeButtonNew={() => navigate('/user/new')}
        changeButtonSave={() => formRef.current?.submitForm()}
        changeButtonDelete={() => handleDelete}
      />
      <Form ref={formRef} onSubmit={handleSave}>
        <Box border="1px solid #c4c4c4" display="flex" gap={1} alignContent="center" alignItems="center" justifyContent="center" flexDirection="column" padding={10}>
          <Box sx={{ width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <VTextField
              name="email"
              fullWidth
              placeholder="Email (Login)"
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box sx={{ width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <VTextField
              name="name"
              fullWidth
              placeholder="Nome"
              variant="filled"
            />
          </Box>
          <Box display="flex" gap={1} sx={{ flexDirection: smDown || mdDown || lgDown ? 'column' : '', width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="crm"
                fullWidth
                placeholder="CRM"
                variant="filled"
              />
            </Box>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="phone"
                fullWidth
                placeholder="Celular"
                variant="filled"
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </LayoutDefault>
  );
};