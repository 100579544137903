import { parseCookies } from 'nookies';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/appRoutes';
import { AuthRoutes } from './routes/authRoute';
import 'react-phone-number-input/style.css';

import { SideBar } from './shared/components';
import { AuthProvider, DrawerProvider } from './shared/contexts';
import { AppThemeProvider } from './shared/contexts/ThemeContext';
import { api } from './shared/services';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IAuth {
  isAuth: boolean;
}
export const App = () => {
  let isAuthenticated;
  const { '@airbnb-Token': token } = parseCookies();

  const result = api[0].defaults.headers['Authorization'] = `${token}`;

  if (result == 'undefined' && result) {
    isAuthenticated = false;
  } else {
    isAuthenticated = true;
  }

  return (
    <AuthProvider>
      <AppThemeProvider>
        <DrawerProvider>
          <BrowserRouter>
            {isAuthenticated ? (
              <SideBar>
                <AuthRoutes />
              </SideBar>
            ) : (
              <AppRoutes />
            )}
          </BrowserRouter>
        </DrawerProvider>
      </AppThemeProvider>
      <ToastContainer />
    </AuthProvider>
  );
};