import React, { useState, FormEvent, useEffect } from 'react';
import { LayoutDefault } from '../../shared/layouts';
import { Grid, TextField, FormControl, CircularProgress, IconButton, FormLabel, RadioGroup, FormControlLabel, Radio, Divider, Button, Modal, Typography, Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import './style.css';
import { api } from '../../shared/services';
import { toast } from 'react-toastify';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

interface IValuePhone {
  phone?: string;
  donation?: string;
  type?: string;
}

export const Email = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState('whatsapp');
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
  });
  const [display, setDisplay] = useState('none');

  // const [value, setValue] = useState<string | undefined>();
  const [value, setValue] = useState<IValuePhone>({
    phone: '',
    donation: '',
    type: '',
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };



  const handleDonation = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (value?.type === undefined || value?.type.length === 0) {
      setValue({
        ...value,
        type: event.target.value,
        donation: ''
      });
      setDisplay('');
    } else {
      setValue({
        ...value,
        type: event.target.value,
        donation: '0.00'
      });
      setDisplay('none');
    }
  };


  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    let data = {};
    if (type.includes('whatsapp')) {
      data = {
        phone: value?.phone,
        type: value?.type,
        min_value_donation: value?.donation
      };
    } else {
      data = {
        email: values.email
      };
    }
    setIsLoading(true);
    try {
      await api[1].post('/send-research', data);
      setValues({ email: '' });
      setValue({
        phone: '',
        type: ''
      });
      handleClose();

      toast.success('Enviado com sucesso!.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch {
      setIsLoading(false);

      toast.error('Não foi possivel enviar, tente novamente.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

    } finally {
      setIsLoading(false);
    }
  };



  const LabelWhatsapp = () => (
    <Grid container spacing={0} alignItems="normal" style={{ marginBlockStart: '6px', marginInlineEnd: '10px' }}>
      <Grid item>
        <WhatsAppIcon fontSize='small' style={{ marginInlineEnd: '2px' }} />
      </Grid>
      <Grid item style={{ marginBlockStart: '-1px' }}>
        WhatsApp
      </Grid>
    </Grid>
  );

  const LabelEmail = () => (
    <Grid container spacing={0} alignItems="normal" style={{ marginBlockStart: '6px', marginInlineEnd: '10px' }}>
      <Grid item>
        <MailOutlineIcon fontSize='small' style={{ marginInlineEnd: '2px' }} />
      </Grid>
      <Grid item style={{ marginBlockStart: '-1px' }}>
        E-mail
      </Grid>
    </Grid>
  );

  const style = {
    position: 'absolute' as const,
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
  const sx = {
    display: 'flex',
    with: '100%',
  };

  return (
    <LayoutDefault title="Enviar Pesquisa">
      <Grid container spacing={3} direction="column">
        <Grid item md={6} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Meio para envio:</FormLabel>
            <RadioGroup row value={type} onChange={event => setType(event.target.value)}>
              <FormControlLabel value="whatsapp" control={<Radio color="primary" />} label={<LabelWhatsapp />} />
              <FormControlLabel value="email" control={<Radio color="primary" />} label={<LabelEmail />} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sx={{ p: 4 }} display="flex" flexDirection="column" width="100%">
          <Grid>
            {type.includes('whatsapp') && (
              <Box sx={{ width: 300, height: 50 }}>
                <PhoneInput placeholder="Celular com DDD ou DDI *" defaultCountry="BR" className='input-phone-number' style={{ width: 300 }} name="whatsapp" value={value?.phone} onChange={(newValue) => setValue({ phone: newValue })} error={value?.phone ? (isValidPhoneNumber(value?.phone) ? undefined : 'Invalid phone number') : 'Phone number required'} />
              </Box>
            )}
            {type.includes('email') && (
              <TextField style={{ width: '300px' }} label='E-mail preferencial' name="email" onChange={handleChange} required value={values.email} variant="outlined" />
            )}
          </Grid>
        </Grid>
        <Divider />
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button variant="outlined" onClick={handleOpen}>Confirmar</Button>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} display="flex" flexDirection="column" width="100vh">
              <Box display="flex" justifyContent="center" sx={{ py: 3 }} alignItems="center">
                <Typography id="modal-modal-title" color="#127EFF" variant="h6" component="h2">
                  Forma de Atendimento
                </Typography>
              </Box>
              <Divider sx={sx} />
              <Box display="flex" justifyContent="center" alignItems="center" alignContent="center" sx={{ py: 2 }}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    onChange={handleDonation}
                  >
                    <FormControlLabel value="with_donation" control={<Radio />} label="Com Doação" />
                    <TextField label="Doação" style={{ display: display }} value={value?.donation} onChange={(newValue) => setValue({ donation: newValue.target.value, phone: value?.phone, type: value?.type })} margin="normal" variant="outlined" fullWidth InputLabelProps={{ shrink: true }} name="min_value_donation" size="small" placeholder="valor minimo" />
                    <FormControlLabel value="" control={<Radio />} label="Sem Doação" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Divider />
              <Box width="100%" sx={{ marginLeft: 1 }} display="flex" justifyContent="end" flexDirection="column" alignItems="end">
                <Box marginRight={2} sx={{ py: 1 }} display="flex" >
                  <Box width={100}>
                    {isLoading ? (
                      <IconButton >
                        <CircularProgress size={20} style={{ color: '#02255C' }} />
                      </IconButton>
                    ) : (
                      <Button variant="outlined" onClick={handleSubmit}>Enviar</Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </LayoutDefault >
  );
};