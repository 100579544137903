import React, { createContext, useState, useCallback, useMemo, useContext } from 'react';
import { ThemeProvider } from '@mui/material';
import { DarkTheme, LightTheme } from '../themes';
import { Box } from '@mui/system';

interface IThemeContextData {
  themeName: string;
  toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

interface IAppThemeProviderProps {
  children: React.ReactNode;
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {

  const getTheme = localStorage.getItem('theme');
  let currentTheme: string;

  if (getTheme) {
    currentTheme = getTheme;
  } else {
    currentTheme = 'light';
  }

  const [themeName, setThemeName] = useState<string>(currentTheme);
  const toggleTheme = useCallback(() => {
    setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
  }, []);

  localStorage.setItem('theme', themeName);

  const theme = useMemo(() => {
    if (themeName === 'light') return LightTheme;
    return DarkTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="100vh" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
