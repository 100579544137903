import { AxiosError } from 'axios';
import { signOut } from '../../../../contexts';

export const errorInterceptor = (error: AxiosError) => {

  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.data === 'This username already exists') {
    return Promise.reject(new Error('This username already exists.'));
  }

  if (error.response && error.response.status === 401) {
    if (typeof window !== undefined) {
      signOut();
    } else {
      return Promise.reject(new Error('Erro de autenticação.'));
    }
  }

  return Promise.reject(error);
};