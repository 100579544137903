export const dataTableStyle = {
  table: {
    style: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
    },
  },
  headRow: {
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '16px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',

    },
    style: {
      fontSize: '14px',
    }
  },
  pagination: {
    style: {
      fontSize: '14px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
    }
  }
};

export const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

export const noData = {
  text: 'Não há registros para exibir'
};