import React, { useState } from 'react';
import { Box, Button, CircularProgress, Icon, MenuItem, Paper, Select, TextField, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

interface IBarFilterTableProps {
  textSearchName?: string;
  showInputSearch?: boolean;
  changeTextSearchName?: (newText: string) => void;

  showInputCRM?: boolean;
  textSearchCRM?: string;
  changeTextSearchCRM?: (newText: string) => void;

  loadingSearch?: boolean;
  changeButtonFilter?: () => void;

  showButtonFilter?: boolean;
  textButtonNew?: string;
  showButtonNew?: boolean;
  changeButtonNew?: () => void;

  textButtonShared?: string;
  showButtonShared?: boolean;
  changeButtonShared?: () => void;

  showButtonBack?: boolean;
  textButtonBack?: string;
  changeButtonBack?: () => void;

  showInputDate?: boolean;
  textInputDate?: string;
  changeInputDate?: (newText: string) => void;

  showInputSelect?: boolean;
  changeInputSelect?: (newText: string) => void;
  textSelected?: string;

  showButtonSave?: boolean;
  showButtonSaveLoading?: boolean;
  textButtonSave?: string;
  changeButtonSave?: () => void;

  showButtonDelete?: boolean;
  textButtonDelete?: string;
  changeButtonDelete?: () => void;
}

export const BarFilterTable: React.FC<IBarFilterTableProps> = ({
  textSearchName = '',
  showInputSearch = false,
  changeTextSearchName,

  textSearchCRM = '',
  showInputCRM = false,
  changeTextSearchCRM,

  loadingSearch = false,
  changeButtonFilter,
  showButtonFilter = false,

  textButtonNew = 'Novo',
  showButtonNew = false,
  changeButtonNew,

  textButtonShared = 'Novo',
  showButtonShared = true,
  changeButtonShared,

  showButtonBack = false,
  textButtonBack = 'Voltar',
  changeButtonBack,

  showButtonSave = false,
  showButtonSaveLoading = false,
  textButtonSave = 'Salvar',
  changeButtonSave,

  showInputDate = false,
  textInputDate = 'Data',
  changeInputDate,

  showInputSelect = false,
  changeInputSelect,
  textSelected = 'all',

  showButtonDelete = false,
  textButtonDelete = 'Apagar',
  changeButtonDelete,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const currUrl = window.location.href;

  const copyUrl = (currentUrl: string) => {
    navigator.clipboard.writeText(currentUrl);

    toast.success('link copiado com successo!');
  };

  return (
    <Box gap={1} marginX={1} marginBottom={1} sx={{ border: '1px solid #c4c4c4', flexDirection: smDown || mdDown ? 'column' : '', height: smDown || mdDown ? '100%' : 40 }} padding={1} paddingX={2} display="flex" height={theme.spacing(5)} component={Paper}>
      {showButtonBack && (
        <Box sx={{ marginZ: 0.5 }}>
          <Button
            style={{ width: '90px', height: 40 }}
            size="small"
            color="primary"
            disableElevation
            variant="outlined"
            endIcon={<Icon>arrow_back</Icon>}
            onClick={changeButtonBack}
          >
            {textButtonBack}
          </Button>
        </Box>
      )}
      {showInputSearch && (
        <Box width={theme.spacing(25)}>
          <TextField size="small" value={textSearchName} onChange={(e) => changeTextSearchName?.(e.target.value)} fullWidth label='Qualquer parte do nome'></TextField>
        </Box>
      )}
      {showInputCRM && (
        <Box width={theme.spacing(25)}>
          <TextField size="small" value={textSearchCRM} onChange={(e) => changeTextSearchCRM?.(e.target.value)} fullWidth label='CRM'></TextField>
        </Box>
      )}
      {showInputDate && (
        <Box>
          <TextField fullWidth label="Data Entrada" InputLabelProps={{ shrink: true }} onChange={(e) => changeInputDate?.(e.target.value)} type="date" size="small" variant="outlined"></TextField>
        </Box>
      )}
      {showInputDate && (
        <Box>
          <TextField fullWidth label="Data Final" InputLabelProps={{ shrink: true }} onChange={(e) => changeInputDate?.(e.target.value)} type="date" size="small" variant="outlined"></TextField>
        </Box>
      )}
      {showInputSelect && (
        <Box>
          <Select value={textSelected} onChange={(e) => changeInputSelect?.(e.target.value)} size="small">
            <MenuItem value={'all'}>Todos Status</MenuItem>
            <MenuItem value={'paid'}>Prontúario Pendente</MenuItem>
            <MenuItem value={'canceled'}>Prontúario Cancelado</MenuItem>
            <MenuItem value={'registered'}>Prontúario Cadastrado</MenuItem>
            <MenuItem value={'pending'}>Pagamento Pendente</MenuItem>
            <MenuItem value={'answered'}>Pagamento Pendente (aguardando)</MenuItem>
          </Select>
        </Box>
      )}
      {showButtonFilter && (
        <Box width={theme.spacing(15)}>
          {loadingSearch ? (
            <Button
              style={{ width: '90px', height: 40 }}
              size="small"
              color="primary"
              variant="outlined"
            >
              <CircularProgress size={25} style={{ color: '#02255C' }} />
            </Button>
          )
            : (
              <Button
                style={{ width: '90px', height: 40 }}
                size="small"
                disableElevation
                color="primary"
                variant="outlined"
                onChange={changeButtonFilter}
              >
                Filtrar
              </Button>
            )}
        </Box>)}
      <Box flex={1} display="flex" sx={{ justifyContent: smDown || mdDown ? 'start' : 'end' }} gap={1}>
        {showButtonDelete && (
          <Box>
            <Button
              style={{ width: '90px', height: 40 }}
              size="small"
              color="primary"
              disableElevation
              variant="outlined"
              endIcon={<Icon>delete</Icon>}
              onClick={changeButtonDelete}
            >
              {textButtonDelete}
            </Button>
          </Box>
        )}
        <Box>
          {(showButtonSave && !showButtonSaveLoading) && (
            <Button
              style={{ width: '90px', height: 40 }}
              size="small"
              color="primary"
              disableElevation
              variant="outlined"
              endIcon={<Icon>save</Icon>}
              onClick={changeButtonSave}
            >
              {textButtonSave}
            </Button>
          )}
          {showButtonSaveLoading && (
            <Button
              style={{ width: '90px', height: 40 }}
              size="small"
              color="primary"
              disableElevation
              variant="outlined"
              onClick={changeButtonSave}
            >
              <CircularProgress size={25} style={{ color: '#02255C' }} />
            </Button>
          )}
        </Box>
        {showButtonNew && (
          <Box>
            <Button
              style={{ width: '90px', height: 40 }}
              size="small"
              color="primary"
              disableElevation
              variant="contained"
              endIcon={<Icon>add</Icon>}
              onClick={changeButtonNew}
            >
              {textButtonNew}
            </Button>
          </Box>
        )}
        {showButtonShared && (
          <Box>
            <Tooltip title="Copiar para compartilhar a pesquisa da tabela..." placement="top-start">
              <Button
                style={{ width: '90px', height: 40 }}
                size="small"
                color="primary"
                onClick={() => copyUrl(currUrl)}
                disableElevation
                variant="contained"
              >
                <Icon>shared</Icon>
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box >
  );
};