import { Grid, Container, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useNavigate, useParams } from 'react-router-dom';
import { BarFilterTable } from '../../../shared/components';
import { LayoutDefault } from '../../../shared/layouts';
import { Form } from '@unform/web';
import { VTextField } from '../../../shared/forms';
import { api } from '../../../shared/services';
import { toast } from 'react-toastify';
import moment from 'moment';

interface ISymptomTable {
  date_created: string,
  ageusa: string,
  anosmia: string,
  astralgia: string,
  caimbras: string,
  cefaleia_leve: string,
  cefaleia_forte: string,
  congestao_nasal: string,
  diarreia: string,
  dor_abdominal: string,
  dor_costas: string,
  dor_olhos: string,
  dor_torax_peito: string,
  edema: string,
  falta_ar_leve: string,
  falta_ar_media: string,
  formigamento: string,
  fraqueza_astemia: string,
  garganta: string,
  garganta_irritada: string,
  inapetencia: string,
  mialgia: string,
  nauseas: string,
  odinofagia: string,
  otalgia: string,
  pressao_olhos: string,
  prurido: string,
  sinusite: string,
  sudorese: string,
  tontura: string,
  coriza: string,
  vomitos: string,
  sat_oxigenio_m: string,
  sat_oxigenio_t: string,
  sat_oxigenio_n: string,
  sat_oxigenio_moderado_m: string,
  sat_oxigenio_moderado_t: string,
  sat_oxigenio_moderado_n: string,
  temperatura_m: string,
  temperatura_t: string,
  temperatura_n: string,
  temperatura_normal_m: string,
  temperatura_normal_t: string,
  temperatura_normal_n: string,
  temperatura_febre_m: string,
  temperatura_febre_t: string,
  temperatura_febre_n: string,
  temperatura_febre_alta_m: string,
  temperatura_febre_alta_t: string,
  temperatura_febre_alta_n: string,
  temperatura_hipertermia_m: string,
  temperatura_hipertermia_t: string,
  temperatura_hipertermia_n: string,
  temperatura_frio_calor_sudorese: string,
  fisiologia_respiratoria_m: string,
  fisiologia_respiratoria_t: string,
  pronar_m: string,
  pronar_t: string,
  pronar_n: string,
  ivermectina: string,
  azitromicina: string,
  vitamina_d: string,
  vitamina_c: string,
  zinco: string,
  curcuma: string,
}

export const NewSymptom = () => {
  const navigate = useNavigate();

  const { id = 'new' } = useParams<'id'>();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [rows, setRows] = useState<ISymptomTable[]>([]);


  const handleSave = async (data: ISymptomTable) => {
    const result = {
      id,
      ...data
    };

    setIsLoading(true);
    await api[1].post('/new-symptom-table', result)
      .then(() => {

        toast.success('Novos sintomas adicionado com sucesso.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        setTimeout(() => {
          setIsLoading(false);
          navigate(`/prontuario/evolution/${id}`);
        }, 5000);

      }).catch((error) => {
        console.log(error);

        toast.error('Error: Verifique os campos e tente novamente.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <LayoutDefault title="Novo Sintomas">
      <BarFilterTable
        showButtonBack={true}
        showButtonSave={true}
        showButtonSaveLoading={isLoading}
        changeButtonSave={() => formRef.current?.submitForm()}
        changeButtonBack={() => navigate(`/prontuario/evolution/${id}`)}
      />
      <Container maxWidth="xl">
        <Form ref={formRef} onSubmit={handleSave}>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={3} xs={12}>
              <VTextField label="Data/Sintoma *" name="date_created" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={3} xs={12}>
              <VTextField label="Ageusa - Perda de paladar" name="ageusa" size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Anosmia - Perda de olfato" name="anosmia" size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Astralgia - Dores articulares" name="astralgia" size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Câimbras" size="small" name="caimbras" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Cefaléia - Leve/Média - Dor de Cabeça" name="cefaleia_leve" size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Cefaléia - Forte - Dor de Cabeça" name="cefaleia_forte" value={rows[0]?.cefaleia_forte} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Congestão Nasal - Narina  'Fechada'" name="congestao_nasal" value={rows[0]?.congestao_nasal} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Coriza" name="coriza" value={rows[0]?.coriza} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Diarréia" name="diarreia" value={rows[0]?.diarreia} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Dor Abdominal" name="dor_abdominal" value={rows[0]?.dor_abdominal} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Dor nas Costas" name="dor_costas" value={rows[0]?.dor_costas} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Dor nos Olhos" name="dor_olhos" value={rows[0]?.dor_olhos} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Dor no Tórax - Dor no Peito" name="dor_torax_peito" value={rows[0]?.dor_torax_peito} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Edema no olhos/Face" name="edema" size="small" value={rows[0]?.edema} InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Falta de ar Leve - Dispnéia" name="falta_ar_leve" value={rows[0]?.falta_ar_leve} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Falta de ar Média/Forte - Dispnéia" name="falta_ar_media" value={rows[0]?.falta_ar_media} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Formigamento" name="formigamento" value={rows[0]?.formigamento} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Fraqueza Astemia/Adinamia" name="fraqueza_astemia" value={rows[0]?.fraqueza_astemia} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Garganta Aperto/Inchada" name="garganta" value={rows[0]?.garganta} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Garganta Irritada - Sem dor" name="garganta_irritada" value={rows[0]?.garganta_irritada} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Inapetência" size="small" name="inapetencia" value={rows[0]?.inapetencia} InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Mialgia - Dores Musculares" name="mialgia" value={rows[0]?.mialgia} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Náuseas" size="small" name="nauseas" value={rows[0]?.nauseas} InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Odinofagia - Dor ao comer" name="odinofagia" value={rows[0]?.odinofagia} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Otalgia - Dor dee ouvido" name="otalgia" value={rows[0]?.otalgia} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Pressão nos Olhos" name="pressao_olhos" value={rows[0]?.pressao_olhos} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Prurido/Coceira" name="prurido" value={rows[0]?.prurido} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Sinusite - Sensação" name="sinusite" value={rows[0]?.sinusite} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Sudorese" name="sudorese" value={rows[0]?.sudorese} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Tontura" name="tontura" value={rows[0]?.tontura} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Vômitos" name="vomitos" value={rows[0]?.vomitos} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'SATURAÇÃO OXIGÊNIO < 93% MODERADO A GRAVE'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="sat_oxigenio_m" value={rows[0]?.sat_oxigenio_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="sat_oxigenio_t" value={rows[0]?.sat_oxigenio_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="sat_oxigenio_n" value={rows[0]?.sat_oxigenio_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'TEMPERATURA ABAIXO 35,8º C HIPOTERMIA'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="sat_oxigenio_moderado_m" value={rows[0]?.sat_oxigenio_moderado_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="sat_oxigenio_moderado_t" value={rows[0]?.sat_oxigenio_moderado_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="sat_oxigenio_moderado_n" value={rows[0]?.sat_oxigenio_moderado_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'TEMPERATURA ENTRE 35,9º A 37,5º C=NORMAL'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="temperatura_m" value={rows[0]?.temperatura_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="temperatura_t" value={rows[0]?.temperatura_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="temperatura_n" value={rows[0]?.temperatura_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'TEMPERATURA ENTRE 37,6º A 39,5º C=FEBRE'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="temperatura_normal_m" value={rows[0]?.temperatura_normal_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="temperatura_normal_t" value={rows[0]?.temperatura_normal_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="temperatura_normal_n" value={rows[0]?.temperatura_normal_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'TEMPERATURA ENTRE 39,6º a 41º C=FEBRE ALTA'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="temperatura_febre_m" value={rows[0]?.temperatura_febre_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="temperatura_febre_t" value={rows[0]?.temperatura_febre_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="temperatura_febre_n" value={rows[0]?.temperatura_febre_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'TEMPERATURA ACIMA DE 41º HIPERTERMIA'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="temperatura_febre_alta_m" value={rows[0]?.temperatura_febre_alta_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="temperatura_febre_alta_t" value={rows[0]?.temperatura_febre_alta_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="temperatura_febre_alta_n" value={rows[0]?.temperatura_febre_alta_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Temperatura no Frio/Calafrio ou Sudorese" name="temperatura_frio_calor_sudorese" value={rows[0]?.temperatura_frio_calor_sudorese} size="small" fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'FISIOLOGIA RESPIRATÓRIA - Marcar com um X se fez'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="fisiologia_respiratoria_m" value={rows[0]?.fisiologia_respiratoria_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="fisiologia_respiratoria_t" value={rows[0]?.fisiologia_respiratoria_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'PRONAR'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="M" name="pronar_m" value={rows[0]?.pronar_m} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="T" name="pronar_t" value={rows[0]?.pronar_t} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="N" name="pronar_n" value={rows[0]?.pronar_n} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={12} xs={12}>
              <Typography>{'MEDICAMENTOS'}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Ivermectina" name="ivermectina" value={rows[0]?.ivermectina} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Azitromicina" name="azitromicina" value={rows[0]?.azitromicina} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Vitamina D" name="vitamina_d" value={rows[0]?.vitamina_d} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Vitamina C" name="vitamina_c" value={rows[0]?.vitamina_c} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Zinco" name="zinco" value={rows[0]?.zinco} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField label="Cúrcuma" name="curcuma" value={rows[0]?.curcuma} size="small" InputLabelProps={{ shrink: true }} fullWidth variant="filled" />
            </Grid>
          </Grid>
        </Form>
      </Container>
    </LayoutDefault >
  );
};