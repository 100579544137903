import { api } from '../..';
import { Environment } from '../../../environment';

export interface IListPatients {
  born_city: string;
  status: string;
  alcohool: Array<string>;
  pre_treatment_hospitalized: string;
  weight: string;
  postal_code: string;
  symptons_now: Array<string>;
  any_desease: Array<string>;
  days_hospitalized: string;
  atual_address_state: string;
  deficiency_other: string;
  treatment: string;
  many_days_medicines: string;
  drugs: string;
  ethnicity: string;
  took_it_on_its_own_medicines: Array<string>;
  valordoacao: string;
  took_it_on_its_own_medicines_other: string;
  atual_address_number: string;
  pre_treatment_yes: string;
  indication_person: string;
  pre_treatment_yes_hospitalized: string;
  payments_id: Array<string>;
  symptom_covid_now: string;
  test_before_treatment: Array<string>;
  deficiency: Array<string>;
  term_accept_3: string;
  term_accept_2: string;
  term_accept_1: string;
  responsible_name: string;
  test_before_treatment_result: string;
  have_any_deseases_others: string;
  took_it_on_its_own_days: string;
  start_date_symptoms: string;
  got_vaccinated_covid: string;
  daily_medication: string;
  any_covid: string;
  born_date: string;
  medicine_before_treatment: Array<string>;
  atual_address_district: string;
  oxygen_saturation: string;
  alergy_medication: string;
  study_for_prophylaxis: string;
  bald: string;
  took_it_on_its_own: string;
  blood_type: string;
  vaccine_first_date: string;
  name: string;
  vaccine_second_date: string;
  drugs_yes: string;
  baby_age: string;
  token: string;
  mother_name: string;
  born_state: string;
  exame_uploads: IDetailUpload[];
  atual_address_street: string;
  pregnant: string;
  why_not_tested: Array<string>;
  id: string;
  polycystic_ovary: string;
  daily_medication_yes: string;
  average_temp_if_have_fever: string;
  have_contact_with_others: string;
  atual_address_city: string;
  first_symptom_date_covid: string;
  date_created: string;
  have_any_deseases: Array<string>;
  atual_address_complement: string;
  gender: string;
  cognito_email_professional: string;
  smoker: Array<string>;
  height: string;
  tel: string;
  cpf: string;
  many_days_symptons: string;
  pre_treatment: string;
  alergy_medication_other: string;
  breastfeeding: string;
}

export interface IDetailUpload {
  public_url: string;
  key_s3: string;
}

type IPatientsCount = {
  data: IListPatients[];
  totalCount: number;
}

const getAll = async (filter = ''): Promise<IPatientsCount | Error> => {
  try {

    const { data, headers } = await api[1].post('/filter-researchs', filter);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LINE_LIMIT),
      };
    }

    return new Error('Erroo ao listar o registro...');

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao listar os registros...');
  }
};

export const Patients = {
  getAll,
};