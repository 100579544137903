import { Box, Button, Card, CardActions, CardContent, CircularProgress, IconButton, ImageListItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services';
import { toast } from 'react-toastify';
interface IFormValues {
  code: string;
  username: string;
  password: string;
}
export const ConfirmForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState<IFormValues>({
    code: '',
    username: '',
    password: ''
  });

  const [emailError, setEmailError] = useState<string>('');
  // const [codeError, setCodeError] = useState<string>('');
  // const [usernameError, setUsernameError] = useState<string>('');
  // const [passwordError, setPasswordError] = useState<string>('');


  const handleSubmit = async () => {
    setIsLoading(true);

    await api[0].post('/confirm-forgot-password', values)
      .then((response) => {
        toast.success('Senha alterada com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        navigate('/login');
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Card>
        <CardContent>
          <Box width={400} display='flex' justifyContent='center' alignItems='center' alignContent='center'>
            <ImageListItem>
              <img src="https://i.ibb.co/zScQ2X1/logo.png" alt="Logo MCare"></img>
            </ImageListItem>
          </Box>
          <Box display='flex' flexDirection='column' gap={2} width={400}>
            <Typography variant='h6'>Recuperar Senha</Typography>
            <TextField
              label='Verificar código'
              value={values.code}
              onChange={prev => setValues({ ...values, code: prev.target.value })}
              onKeyDown={() => setEmailError('')}
              error={!!emailError} helperText={emailError}
              type='code'
              required
              fullWidth>
            </TextField>
            <TextField
              label='Email'
              value={values.username}
              onChange={prev => setValues({ ...values, username: prev.target.value })}
              onKeyDown={() => setEmailError('')}
              error={!!emailError} helperText={emailError}
              type='email'
              required
              fullWidth>
            </TextField>
            <TextField
              label='Nova senha'
              value={values.password}
              onChange={prev => setValues({ ...values, password: prev.target.value })}
              onKeyDown={() => setEmailError('')}
              error={!!emailError} helperText={emailError}
              type='password'
              required
              fullWidth>
            </TextField>
          </Box>
        </CardContent>
        <CardActions>
          <Box width='100%' display='flex' justifyContent='center' gap={1}>
            {isLoading ? (
              <IconButton >
                <CircularProgress size={20} style={{ color: '#02255C' }} />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => navigate('/login')}
            >
              Voltar
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box >
  );
};