import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarFilterTable } from '../../../shared/components';
import { LayoutDefault } from '../../../shared/layouts';
import { Grid, Container, TextField, Typography } from '@mui/material';
import { ISymptomTable, MedicalRecordService } from '../../../shared/services/api/entity/MedicalRecords';
import moment from 'moment';

type IUseParams = {
  id: string,
  id_symptom: string
}
export const EditSymptom = () => {
  const navigate = useNavigate();

  const { id, id_symptom } = useParams<IUseParams>();
  const [rowsSymp, setRowsSymp] = useState<ISymptomTable[]>([]);

  const handleSearch = async () => {
    await MedicalRecordService.getAll({ id: id })
      .then((response) => {
        if (response instanceof Error) {
          if (response.message === 'Erro de conexão.') {
            location.reload();
          }
        } else {
          const result = response.data;
          let i = 0;
          result[0].symptom_table.forEach((item) => {
            i++;
            if (item.symptom_table_id === id_symptom) {
              setRowsSymp([item]);
            }
          });

        }
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <LayoutDefault title="Evolução">
      <BarFilterTable
        showButtonBack={true}
        changeButtonBack={() => navigate(`/prontuario/evolution/${id}`)}
      />
      <Container maxWidth="xl">
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={3} xs={12}>
            <TextField label='Data/Sintoma *' type="date" size="small" InputLabelProps={{ shrink: true }} value={moment(rowsSymp[0]?.date_created).format('YYYY-MM-DD')} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={3} xs={12}>
            <TextField label='Ageusa - Perda de paladar' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.ageusa} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Anosmia - Perda de olfato' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.anosmia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Astralgia - Dores articulares' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.astralgia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Câimbras' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.caimbras} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Cefaléia - Leve/Média - Dor de Cabeça' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.cefaleia_leve} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Cefaléia - Forte - Dor de Cabeça' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.cefaleia_forte} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Congestão Nasal - Narina "Fechada"' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.congestao_nasal} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Coriza' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.coriza} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Diarréia' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.diarreia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Dor Abdominal' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.dor_abdominal} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Dor nas Costas' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.dor_costas} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Dor nos Olhos' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.dor_olhos} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Dor no Tórax - Dor no Peito' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.dor_torax_peito} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Edema no olhos/Face' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.edema} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Falta de ar Leve - Dispnéia' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.falta_ar_leve} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Falta de ar Média/Forte - Dispnéia' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.falta_ar_media} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Formigamento' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.formigamento} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Fraqueza Astemia/Adinamia' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.fraqueza_astemia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Garganta Aperto/Inchada' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.garganta} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Garganta Irritada - Sem dor' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.garganta_irritada} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Inapetência' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.inapetencia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Mialgia - Dores Musculares' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.mialgia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Náuseas' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.nauseas} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Odinofagia - Dor ao comer' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.odinofagia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Otalgia - Dor dee ouvido' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.otalgia} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Pressão nos Olhos' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.pressao_olhos} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Prurido/Coceira' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.prurido} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Sinusite - Sensação' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sinusite} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Sudorese' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sudorese} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Tontura' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.tontura} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Vômitos' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.vomitos} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'SATURAÇÃO OXIGÊNIO < 93% MODERADO A GRAVE'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'TEMPERATURA ABAIXO 35,8º C HIPOTERMIA'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_moderado_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_moderado_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.sat_oxigenio_moderado_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'TEMPERATURA ENTRE 35,9º A 37,5º C=NORMAL'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'TEMPERATURA ENTRE 37,6º A 39,5º C=FEBRE'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_normal_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_normal_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_normal_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'TEMPERATURA ENTRE 39,6º a 41º C=FEBRE ALTA'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'TEMPERATURA ACIMA DE 41º HIPERTERMIA'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_alta_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_alta_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.temperatura_febre_alta_n} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Temperatura no Frio/Calafrio ou Sudorese' size="small" value={rowsSymp[0]?.temperatura_frio_calor_sudorese} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'FISIOLOGIA RESPIRATÓRIA - Marcar com um X se fez'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.fisiologia_respiratoria_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.fisiologia_respiratoria_t} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'PRONAR'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='M' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.pronar_m} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='T' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.pronar_t} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='N' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.pronar_n} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={12} xs={12}>
            <Typography>{'MEDICAMENTOS'}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Ivermectina' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.ivermectina} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Azitromicina' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.azitromicina} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Vitamina D' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.vitamina_d} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Vitamina C' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.vitamina_c} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Zinco' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.zinco} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Cúrcuma' size="small" InputLabelProps={{ shrink: true }} value={rowsSymp[0]?.curcuma} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
      </Container>
    </LayoutDefault>
  );
};