import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from '../shared/components';
import { ForgotPassword } from '../shared/components/login/ForgotPassword';
import { ConfirmForgotPassword } from '../shared/components/login/ConfirmForgotPassword';

export const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/confirm-forgot-pass" element={<ConfirmForgotPassword />} />
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Routes >
  );
};