import React, { useEffect, useState } from 'react';
import { BarFilterTable } from '../../../shared/components';
import { LayoutDefault } from '../../../shared/layouts';
import { Grid, TextField, Container, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvolutions, IMedicalRecord, MedicalRecordService } from '../../../shared/services/api/entity/MedicalRecords';
import moment from 'moment';

type IUseParams = {
  id: string;
  evolution_id: string;
}

export const EditEvolution = () => {
  const navigate = useNavigate();
  const [rowsEvo, setRowsEvo] = useState<IEvolutions[]>([]);
  const { id, evolution_id } = useParams<IUseParams>();

  const [value, setValue] = useState({
    name: '',
    date: moment().format('YYYY-MM-DD'),
    tel: '',
    register: '',
    register_date: moment().format('YYYY-MM-DD'),
    email: '',
    mobile: '',
    test_covid: 'no',
    result_test_covid: '',
    allergies: 'no',
    medication: '',
    health_issues: 'none',
    symptoms: 'none',
    symptoms_date: moment().format('YYYY-MM-DD'),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = async () => {
    await MedicalRecordService.getAll({ id: id })
      .then((response) => {
        if (response instanceof Error) {
          if (response.message === 'Erro de conexão.') {
            location.reload();
          }
        } else {
          
          const result = response.data;

          let i = 0;
          result[0].evolutions.forEach((item) => {
            i++;
            if (item.evolution_id === evolution_id) {
              setRowsEvo([item]);
            }
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <LayoutDefault title="Evolução">
      <BarFilterTable
        showButtonBack={true}
        changeButtonBack={() => navigate(`/prontuario/evolution/${id}`)}
      />
      <Container maxWidth="lg">
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={9} xs={12}>
            <TextField label='Nome' placeholder='nome' name={'name'} size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.name} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Data de Atentimento' type="date" placeholder='data de atentimento' name="date" size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.date} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={9} xs={12}>
            <TextField label='Registro' placeholder='Registro' name="register" size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.register} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Data de Registro' type="date" placeholder='Data de registro' name={'register_date'} size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.register_date} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={6} xs={12}>
            <TextField label='Email' name="email" placeholder='email' size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.email} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Celular' placeholder='Data de registro' size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.mobile} fullWidth variant='filled'></TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField label='Telefone' placeholder='telefone' size="small" InputLabelProps={{ shrink: true }} value={rowsEvo[0]?.tel} fullWidth variant='filled'></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={9} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Teste de COVID-19</FormLabel>
              <RadioGroup aria-label="test_covid" value={value} onChange={handleChange} name="test_covid">
                <FormControlLabel value="yes" checked={rowsEvo[0]?.test_covid === 'yes'} control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="no" checked={rowsEvo[0]?.test_covid === 'no'} control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              value={rowsEvo[0]?.result_test_covid}
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={5}
              label="Resultado"
              name="result_test_covid"
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={9} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Alergias</FormLabel>
              <RadioGroup aria-label="allergies" value={value} onChange={handleChange} name="allergies">
                <FormControlLabel value="yes" checked={rowsEvo[0]?.allergies === 'yes'} control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel value="no" checked={rowsEvo[0]?.allergies === 'no'} control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              value={rowsEvo[0]?.medication}
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={5}
              label="Medicação"
              name="medication"
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={5}>
          <Grid item md={6} xs={12}>
            <TextField
              size="small"
              value={rowsEvo[0]?.health_issues}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Problemas de saúde"
              name="health_issues"
              variant="filled"
              select
              SelectProps={{ native: true }}
              defaultValue="none"
            >
              <option selected value="none">Não</option>
              <option value="1">Sim</option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              value={rowsEvo[0]?.symptoms}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Sintomas"
              name="symptoms"
              variant="filled"
              select
              SelectProps={{ native: true }}
              defaultValue="none"
            >
              <option selected value="none">Não</option>
              <option value="1">Sim</option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              type="date"
              value={rowsEvo[0]?.symptoms_date}
              fullWidth
              label="Data de início dos sintomas"
              name="symptoms_date"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </Container>
    </LayoutDefault>

  );
};