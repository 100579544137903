import React, { useState, useEffect } from 'react';
import { BarFilterTable } from '../../../shared/components';
import { LayoutDefault } from '../../../shared/layouts';
import { Grid, TextField, Select, CardHeader, Typography, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { IListPatients, Patients } from '../../../shared/services/api/entity/Patients';


export const PatientForm = () => {
  const [rows, setRows] = useState<IListPatients[]>([]);
  const navigate = useNavigate();

  const { id = 'new' } = useParams<'id'>();

  rows[0]?.exame_uploads.map((item) => {
    // console.log(item.public_url);
  });

  const handleSearch = async () => {
    await Patients.getAll()
      .then((response) => {
        if (response instanceof Error) {
          console.error(response);
          location.reload();
        } else {
          const result = response.data;

          result.map((item) => {
            if (item.id === id) {
              setRows([item]);
            }
          });
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <LayoutDefault title="Dados do Pacientes">
      <BarFilterTable
        showButtonBack={true}
        changeButtonBack={() => navigate('/patients')}
      />
      <Grid container spacing={1} marginTop={5}>
        <Grid item md={3}>
          <TextField name="name" label="Nome" InputLabelProps={{ shrink: true }} value={rows[0]?.name} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="born_date" label="Data de Nascimento" type="date" InputLabelProps={{ shrink: true }} value={rows[0]?.born_date} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="cpf" label="cpf" InputLabelProps={{ shrink: true }} value={rows[0]?.cpf} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="tel" label="Telefone" type="tel" InputLabelProps={{ shrink: true }} value={rows[0]?.tel} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="born_state" label="Estado onde nasceu" InputLabelProps={{ shrink: true }} value={rows[0]?.born_state} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="born_city" label="Cidade onde nasceu" InputLabelProps={{ shrink: true }} value={rows[0]?.born_city} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            fullWidth
            label="Gênero"
            name="gender"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.gender}
            select
            SelectProps={{ native: true }}
          >
            <option value="all">Selecione*</option>
            <option value="F">Feminino</option>
            <option value="M">Masculino</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField size="small" fullWidth label="Etnia" name="ethnicity" InputLabelProps={{ shrink: true }} value={rows[0]?.ethnicity} InputProps={{
            readOnly: true,
          }} variant="filled" select SelectProps={{ native: true }} >
            <option value="all">Selecione*</option>
            <option value="branco">Branco</option>
            <option value="negro">Negro</option>
            <option value="pardo">Pardo</option>
            <option value="amarelo">Amarelo</option>
            <option value="indigena">Indígena</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField name="height" label="Altura" InputLabelProps={{ shrink: true }} value={rows[0]?.height} fullWidth size='small' variant='filled' />
        </Grid>
        <Grid item md={3}>
          <TextField name="weight" label="Peso" InputLabelProps={{ shrink: true }} value={rows[0]?.weight} fullWidth size='small' variant='filled' />
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={5}>
        <Grid item md={3}>
          <TextField
            size="small"
            fullWidth
            label="Tipo sanguíneo"
            name="blood_type"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            InputLabelProps={{ shrink: true }} value={rows[0]?.blood_type}
            select
            SelectProps={{ native: true }}
          >
            <option value="ns">Não Sei</option>
            <option value="a_pos">A+</option>
            <option value="a_neg">A-</option>
            <option value="b_pos">B+</option>
            <option value="b_neg">B-</option>
            <option value="ab_pos">AB+</option>
            <option value="ab_neg">AB-</option>
            <option value="o+">O+</option>
            <option value="o-">O-</option>
          </TextField>
        </Grid>
        <Grid item md={4}>
          <TextField size="small" InputLabelProps={{ shrink: true }} value={rows[0]?.responsible_name} fullWidth label="Nome do responsável (se for menor de idade)" name="responsible_name" InputProps={{
            readOnly: true,
          }} variant="filled"
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            size="small"
            fullWidth
            label="Nome da mãe"
            name="mother_name"
            InputLabelProps={{ shrink: true }} value={rows[0]?.mother_name}
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <CardHeader title="Endereço atual" />
      <Grid container gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            fullWidth
            label="CEP"
            InputLabelProps={{ shrink: true }} value={rows[0]?.postal_code}
            name="postal_code"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_street}
            fullWidth
            label="Endereço"
            name="atual_address_street"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_number}
            fullWidth
            label="Número"
            name="atual_address_number"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_complement}
            fullWidth
            label="Complemento"
            name="atual_address_complement"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_district}
            fullWidth
            label="Bairro"
            name="atual_address_district"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_state}
            fullWidth
            label="Estado"
            name="atual_address_state"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.atual_address_city}
            fullWidth
            label="Cidade"
            name="atual_address_city"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <CardHeader title="Dados do tratamento" />
      <Grid container gap={1}>
        <Grid item md={6}>
          <TextField
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }} value={rows[0]?.treatment}
            label="Tratamento"
            name="treatment"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="all">Selecione uma opção</option>
            <option value="preventive_prophylactic">Quero tratamento Profilático / Preventivo</option>
            <option value="symptom_treatment">Quero tratamento pelos sintomas</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }} value={rows[0]?.first_symptom_date_covid}
            fullWidth
            label="Data do primeiro sintoma"
            name="first_symptom_date_covid"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.alergy_medication}
            fullWidth
            label="Alergia à medicamento?"
            name="alergy_medication"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }} value={rows[0]?.alergy_medication_other}
            fullWidth
            label="Qual medicamento?"
            name="alergy_medication_other"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <Typography>Teve alguma dessas doenças?</Typography>
          <Select
            multiple
            native
            value={rows[0]?.any_desease}
            name="any_desease"
            inputProps={{
              id: 'select-multiple-native',
            }}
            fullWidth
          >
            <option value="none">Não tive nenhuma das doenças acima</option>
            <option value="zika">Zika</option>
            <option value="dengue">Dengue</option>
            <option value="chikungunya">Chikungunya</option>
            <option value="tuberculose">Tuberculose</option>
            <option value="febre_amarela">Febre Amarela</option>
          </Select>
        </Grid>
      </Grid>
      <Grid container gap={1} marginTop={5}>
        <Grid item md={3}>
          <Typography>Você possui alguma deficiência?</Typography>
          <Select
            multiple
            native
            value={rows[0]?.deficiency}
            name="deficiency"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="none">Não tenho</option>
            <option value="1">Física</option>
            <option value="2">Auditiva</option>
            <option value="3">Visual</option>
            <option value="4">Mental</option>
            <option value="5">Outros</option>
          </Select>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.deficiency_other}
            fullWidth
            label="Se marcou Outros, diga-nos qual."
            name="deficiency_other"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.bald}
            fullWidth
            label="Você tem calvice [careca] ?"
            name="bald"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="none">Não sou Careca</option>
            <option value="yes">Sou Careca</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.pregnant}
            fullWidth
            label="Você esta grávida, ou existe a possibilidade?"
            name="pregnant"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="none">Não Estou Grávida</option>
            <option value="1">Estou Grávida</option>
            <option value="2">Não Sei Dizer</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.breastfeeding}
            fullWidth
            label="Você esta amamentando?"
            name="breastfeeding"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="none">Não</option>
            <option value="1">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.baby_age}
            fullWidth
            label="Se SIM, qual a idade do bebê?"
            name="baby_age"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.polycystic_ovary}
            fullWidth
            label="Você tem ovários policisticos?"
            name="polycystic_ovary"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
            <option value="dont_know">Não sei dizer</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.any_covid}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            label="Você ja teve a COVID-19?"
            name="any_covid"
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="1">1 vez</option>
            <option value="2">2 vezes</option>
            <option value="3">3 vezes</option>
            <option value="4_more">4 +</option>
            <option value="dont_know">Não sei dizer</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            value={rows[0]?.got_vaccinated_covid}
            fullWidth
            label="Se vacinou contra a COVID-19?"
            name="got_vaccinated_covid"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não tomei</option>
            <option value="CORONAVAC">CORONAVAC</option>
            <option value="ASTRA_ZENECA">ASTRA ZENECA</option>
            <option value="JOHNSON">JOHNSON &amp; JOHNSON</option>
            <option value="PFIZER">PFIZER</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.vaccine_first_date}
            fullWidth
            label="Qual a data da primeira dose tomada?"
            name="vaccine_first_date"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.vaccine_second_date}
            fullWidth
            label="Se tomou a segunda dose, qual a data?"
            name="vaccine_second_date"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.symptom_covid_now}
            fullWidth
            label="Você esta com sintomas agora?"
            name="symptom_covid_now"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.start_date_symptoms}
            fullWidth
            label="Data de início dos sintomas?"
            name="start_date_symptoms"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.many_days_symptons}
            fullWidth
            label="Há quantos dias você esta com sintomas?"
            name="many_days_symptons"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.have_contact_with_others}
            fullWidth
            label="Se está com sintomas, teve contato com quantas pessoas?"
            name="have_contact_with_others"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={6}>
          <Typography>Quais os seus sinais/sintomas agora?</Typography>
          <Select
            multiple
            native
            value={rows[0]?.symptons_now}
            name="symptons_now"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="none">Nenhum sintoma</option>
            <option value="purple_lip">Labios roxos</option>
            <option value="purple_finger">Pontas dos dedos roxa</option>
            <option value="chills">Calafrios</option>
            <option value="nausea">Nauseas</option>
            <option value="pallor">Palidez</option>
            <option value="mental_confusion">Confusão mental</option>
            <option value="nasal_congestion">Congestão nasal</option>
            <option value="swollen_eyes">Olhos Inchados (EDEMA)</option>
            <option value="coriza">Coriza</option>
            <option value="difficulty_swallowing">Dificuldade de engolir</option>
            <option value="loss_of_taste">Perda de paladar</option>
            <option value="hot_body_37">Corpo quente (Temp menor 37.5)</option>
            <option value="loss_of_smell">Perda de olfato (Cheiro)</option>
            <option value="high_pressure">Pressão alta</option>
            <option value="low_pressure">Pressão baixa</option>
            <option value="difficulty_breathing">Dificuldade para respirar</option>
            <option value="joint_pain">Dores articulares</option>
            <option value="chest_pain">Dor no peito</option>
            <option value="headache">Dor de cabeça</option>
            <option value="shortness_of_breathe">Falta de ar</option>
            <option value="backache">Dor nas costas</option>
            <option value="short_breath">Respiração curta/rapida</option>
            <option value="throat_pain">Dor de garganta</option>
            <option value="fainting_sensation">Sensação de desmaio</option>
            <option value="pain_in_eyes">Dor nos olhos</option>
            <option value="sinusitis">Sinusite</option>
            <option value="chest_paint">Dor no peito</option>
            <option value="sweating">Sudorese</option>
            <option value="muscle_aches">Dores Musculares</option>
            <option value="dry_cough">Tosse Seca</option>
            <option value="pain_in_the_lower_back">Dor na região lombar</option>
            <option value="cough_with_phlegm">Tosse com catarro</option>
            <option value="diarrhea">Diarréia</option>
            <option value="cough_with_blood">Tosse com sangue</option>
            <option value="fatigue">Fraqueza/fadiga (Falta energia)</option>
            <option value="tremors">Tremores</option>
            <option value="vomiting">Vômitos</option>
          </Select>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.average_temp_if_have_fever}
            fullWidth
            label="Se vocês estiver com febre, qual a média da temperatura?"
            name="average_temp_if_have_fever"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="not_measured">Não medi a temperatura</option>
            <option value="hypothermia">Abaixo de 35.8C [HIPOTERMIA]</option>
            <option value="normal_temp">Entre 37.6C a 37.5C [TEMPERATURA NORMAL]</option>
            <option value="fever">Entre 37.6C a 39.5C [FEBRE]</option>
            <option value="high_fever">Entre 39.6C a 41.0C [FEBRE ATLTA]</option>
            <option value="hyperthermia">Acima 41.0C [HIPERTERMIA]</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.oxygen_saturation}
            fullWidth
            label="Você mediu sua saturação oxigênio?"
            name="oxygen_saturation"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="not_measured">Não medi minha saturação</option>
            <option value="98_100">98% a 100%</option>
            <option value="95_97">95% a 97%</option>
            <option value="91_94">91% a 94%</option>
            <option value="less_than_90">Menor que 90%</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <Typography>Antes de iniciar este tratamento procurou algum serviço hospitalar de pronto socorro ou lagum outro médico para tratar a COVID-19 ?</Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.pre_treatment}
            fullWidth
            label="Antes de iniciar este tratamento procurou algum serviço hospitalar de pronto socorro ou lagum outro médico para tratar a COVID-19 ?"
            name="pre_treatment"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.pre_treatment_yes}
            fullWidth
            label="Se sim, qual?"
            name="pre_treatment_yes"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="">Selecione uma opção</option>
            <option value="public">Publico</option>
            <option value="private">Privado</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={6}>
          <Typography>Antes de iniciar este tratamento, você já ficou internado em alguma unidade hospitalar para tratar a COVID-19 ?</Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.pre_treatment_hospitalized}
            fullWidth
            name="pre_treatment_hospitalized"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.pre_treatment_yes_hospitalized}
            fullWidth
            label="Se sim, qual?"
            name="pre_treatment_yes_hospitalized"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value=" ">Selecione uma opção</option>
            <option value="nursery">Enfermaria</option>
            <option value="uti">UTI</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.days_hospitalized}
            fullWidth
            label="Se sim, quanto tempo ficou internado?"
            name="days_hospitalized"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value=" ">Selecione uma opção</option>
            <option value="0_4">0 a 4 dias</option>
            <option value="5_10">5 a 10 dias</option>
            <option value="11_15">11 a 15 dias</option>
            <option value="15_more">+15 dias</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={4}>
          <Typography>Antes de iniciar este tratamento, você já fez algum teste para saber se teve ou não a COVID-19?</Typography>
          <Select
            multiple
            native
            value={rows[0]?.test_before_treatment}
            name="test_before_treatment"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="none">Não fiz teste</option>
            <option value="quick_swab_test">Teste rápido - cotonete (farmácia)</option>
            <option value="quick_blood_test">Teste rápido - sangue (farmácia)</option>
            <option value="rt_pcr_swab">RT PCR - cotonete</option>
            <option value="serology">Sorologia IgM/IgG</option>
            <option value="neutralizing_antibodies">Pesquisa anticorpos neutralizantes</option>
          </Select>
        </Grid>
        <Grid item md={4}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.test_before_treatment_result}
            fullWidth
            label="Qual resultado"
            name="test_before_treatment_result"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="negative">Não detectado / Negativo</option>
            <option value="positive">Detectado / Positivo</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <Typography>Se não fez o teste, qual o motivo?</Typography>
          <Select
            multiple
            value={rows[0]?.why_not_tested}
            native
            name="why_not_tested"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="not_symptoms">Não achou necessário / Não teve sintomas</option>
            <option value="soft_symptoms">Não achou necessário / Sintomas leves gripais</option>
            <option value="not_medical_insurance">Não tem convênio</option>
            <option value="financial">Financeiro</option>
            <option value="no_doctor_asked">Nenhum médico solicitou</option>
          </Select>
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={4}>
          <Typography>Antes de iniciar este tratamento, algum profissional de saúde passou alguma dessas medicações para tratamento da COVID-19?</Typography>
          <Select
            multiple
            value={rows[0]?.medicine_before_treatment}
            native
            name="medicine_before_treatment"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="did_not_pass">Não passou</option>
            <option value="amoxacilina">Amoxacilina</option>
            <option value="azitromicina">Azitromicina</option>
            <option value="claritromicina">Claritromicina</option>
            <option value="levofloxacina">Levofloxacina</option>
            <option value="carelto">Xarelto</option>
            <option value="dipirona">Dipirona</option>
            <option value="predinisona">Predinisona</option>
            <option value="dexametasona">Dexametasona</option>
            <option value="zinnat">Zinnat</option>
            <option value="oseltamivir">Oseltamivir [Tamiflu]</option>
            <option value="aas">A A S</option>
            <option value="paracetamol">Paracetamol</option>
            <option value="doxiciclina">Doxiciclina</option>
            <option value="none_of_previous">Nenhuma das anteriores</option>
          </Select>
        </Grid>
        <Grid item md={4}>
          <Typography>Antes deste tratamento, algum médico associou alguma destas medicações que hoje estão em estudo para Profilaxia / Prevenção e tratamento da COVID-19 ?</Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.study_for_prophylaxis}
            fullWidth
            name="study_for_prophylaxis"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={4}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.many_days_medicines}
            fullWidth
            label="Está tomando há quantos dias?"
            name="many_days_medicines"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={2}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.took_it_on_its_own}
            fullWidth
            label="Ou você ja tomou por conta própria?"
            name="took_it_on_its_own"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={2}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.took_it_on_its_own_days}
            fullWidth
            label="Se sim, está tomando ha quantos dias?"
            name="took_it_on_its_own_days"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={6}>
          <Typography>Se sim, quais medicamentos foram utilizados?</Typography>
          <Select
            multiple
            value={rows[0]?.took_it_on_its_own_medicines}
            native
            name="took_it_on_its_own_medicines"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="hydroxychloroquine">Hidroxicloroquina</option>
            <option value="ivermectin">Ivermectina</option>
            <option value="chloroquine">Cloroquina</option>
            <option value="nitazoxanide">Nitazoxadina [Anitta]</option>
            <option value="pyrantel_pamoate">Pomoato de pirantel</option>
            <option value="vitamin_a">Vitamina A</option>
            <option value="vitamin_b">Vitamina B1, B6, B12</option>
            <option value="vitamin_c">Vitamina C</option>
            <option value="zinc">Zinco</option>
            <option value="azitromicina">Azitromicina</option>
            <option value="others">Outra</option>
          </Select>
        </Grid>
        <Grid item md={2}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.took_it_on_its_own_medicines_other}
            fullWidth
            label="Outra"
            name="took_it_on_its_own_medicines_other"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5} gap={1}>
        <Grid item md={6}>
          <Typography>Você tem alguma destas doenças?</Typography>
          <Select
            multiple
            value={rows[0]?.have_any_deseases_others}
            native
            name="have_any_deseases"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="no">Não</option>
            <option value="asthma">Asma</option>
            <option value="bronchitis">Bronquite alérgica</option>
            <option value="diabetes">Diabetes</option>
            <option value="kidney">Doença renal</option>
            <option value="auto_immune">Doença auto imune</option>
            <option value="hypertension">Hipertensão [Pressão Alta]</option>
            <option value="obesity">Obesidade / Esteatose</option>
            <option value="avc">AVC (Derrame)</option>
            <option value="heart_disease">Cardiopatia (Doença do Coração)</option>
            <option value="heart">Doença do coração</option>
            <option value="hepatitis">Doença hepática</option>
            <option value="thyroid">Doença tireoide</option>
            <option value="cancer">Câncer</option>
            <option value="others">Outros</option>
          </Select>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.have_any_deseases_others}
            fullWidth
            label="Outros"
            name="have_any_deseases_others"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>

      {/* <!-- Medicação diária --> */}
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.daily_medication}
            fullWidth
            label="Você toma alguma medicação diariamente?"
            name="daily_medication"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.daily_medication_yes}
            fullWidth
            label="Se sim, qual?"
            name="daily_medication_yes"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item md={6}>
          <Typography>Fumante?</Typography>
          <Select
            multiple
            name="smoker"
            value={rows[0]?.smoker}
            native
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="never">Nunca fumou</option>
            <option value="yes">Sim</option>
            <option value="ex">Ex fumante</option>
            <option value="proved">Provou</option>
          </Select>
        </Grid>
        <Grid item md={6}>
          <Typography>Bebida alcoólica</Typography>
          <Select
            multiple
            value={rows[0]?.alcohool}
            native
            name="alcohool"
            inputProps={{
              id: 'select-multiple-native',
              readOnly: true,
            }}
            fullWidth
          >
            <option value="sociel">Social</option>
            <option value="never">Nunca fez uso</option>
            <option value="ex">Ex alcólatra</option>
          </Select>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.drugs}
            label="Já usou drogas ilícitas?"
            name="drugs"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.drugs_yes}
            fullWidth
            label="Se sim, qual?"
            name="drugs_yes"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>

      {/* <!-- Indicação --> */}
      <Grid container marginTop={5} gap={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.indication_person}
            fullWidth
            label="Pessoa que indicou o tratamento"
            name="indication_person"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      <CardHeader title="Exames" />
      <Grid container gap={1}>
        <Grid item md={3}>
          {rows[0]?.exame_uploads ? (
            <>
              {rows[0]?.exame_uploads.map((item) => (
                <Link variant="body2" href={item.public_url} target="_blank" key={item.public_url}>
                  Baixar exame Anexado
                </Link>
              ))}
            </>
          ) : (
            <TextField
              size="small"
              InputLabelProps={{ shrink: true }}
              value={rows[0]?.exame_uploads}
              fullWidth
              label="Carregar exames"
              name="exame_uploads"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography>
            Declaro que li e fui esclarecido (a) sobre o {'Termo de Consentimento Livre e Esclarecido'} e concordo por livre e espontânea vontade o uso da medicação em estudo e demais necessárias em meu tratamento na COVID 19, mesmo sabendo que ainda não tem comprovação científica, estudo esse chamado de OFF LABEL.
          </Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.term_accept_1}
            fullWidth
            name="term_accept_1"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value=" ">Selecione uma opção</option>
            <option value="yes">Estou de acordo</option>
          </TextField>
        </Grid>
      </Grid>

      <Grid container marginTop={5} gap={1}>
        <Grid item md={6}>
          <Typography>
            Você autoriza que as informações deste formulário sejam cadastradas no Portal MÉDITT de Pesquisa para que o médico possa acessá-las?
          </Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.term_accept_2}
            fullWidth
            name="term_accept_2"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value=" ">Selecione uma opção</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
      </Grid>

      {/* <!-- Aceite --> */}
      <Grid container marginTop={5} gap={1}>
        <Grid item md={6}>
          <Typography>
            Autorizo o compartilhamento dos meus dados, inseridos nesse formulário, para pesquisa, sabendo que eles são essenciais para que eu possa receber atendimento médico.
          </Typography>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            value={rows[0]?.term_accept_3}
            fullWidth
            name="term_accept_3"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            select
            SelectProps={{ native: true }}
          >
            <option value=" ">Selecione uma opção</option>
            <option value="yes">Sim</option>
          </TextField>
        </Grid>
      </Grid>

    </LayoutDefault >
  );
};