import { useCallback, useRef } from 'react';

export const UseDebounce = (delay = 300, notFisrtLoad = true) => {
  const debauncing = useRef<NodeJS.Timeout>();
  const isFirstLoad = useRef(notFisrtLoad);
  const debounce = useCallback((func: () => void) => {

    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      func();
    } else {

      if (debauncing.current) {
        clearTimeout(debauncing.current);
      }

      debauncing.current = setTimeout(() => func(), delay);
    }

  }, [delay]);

  return { debounce };
};