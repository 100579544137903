export const Environment = {
  /** 
   * Default number of rows for listing
   */
  LINE_LIMIT: 10,
  /**
   * Default message for input fields
   */
  SEARCH_INPUT: 'Pesquisar...',
  /**
   * Default message for input fields
   */
  LOOKING_RECORD: 'Procurando o registro...',
  /**
   * Default message for results not found
   */
  EMPTY_LISTING: 'Nenhum registro encontrado.',
  /**
   * URL Base for api requests
   */
  // URL_BASE: 'http://localhost:3333',
  URL_BASE: 'https://doo66h8hw0.execute-api.us-east-2.amazonaws.com/Prod',
  URL_AUTH: 'https://ymjbzpemh0.execute-api.us-east-2.amazonaws.com/Prod'
};