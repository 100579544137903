import { Card, CardHeader, CardContent, Divider, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { MenuAppBar } from '../components';

interface ILayoutDefaultProps {
  children: React.ReactNode;
  title: string;
}

export const LayoutDefault: React.FC<ILayoutDefaultProps> = ({ children, title }) => {

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box height="100vh" display="flex" flexDirection="column" gap={1}>
      <MenuAppBar />
      <Box padding={1} gap={1} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}>
        <Card>
          <CardHeader title={title} />
          <Divider style={{ border: '1px solid #c4c4c4' }} />
          <CardContent>
            {children}
          </CardContent>
        </Card>
      </Box>
    </Box>


  );
};