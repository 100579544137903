import React, { useState, FormEvent } from 'react';
import { Box, CardContent, Card, TextField, CardActions, Typography, Button, IconButton, CircularProgress, ImageListItem } from '@mui/material';
import { api } from '../../services';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState('');

  const navigate = useNavigate();

  const handleForgot = async (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    const data = {
      username: email,
    };

    await api[0].post('/forgot-password', data)
      .then((response) => {
        if (response instanceof Error) {
          toast.error('E-mail inválido ou limite de tentativas alcançadas!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setIsLoading(false);
        } else {
          if (response.data.message === 'Please check your Registered email id for validation code') {
            setEmailError('Por favor, verifique se o email está registrado, ou se está correto.');
            setIsLoading(false);
            navigate('/confirm-forgot-pass');
            toast.success('Verifique se o seu e-mail está registrado para obter o código de validação!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          if (response.status === 400) {
            setEmailError('E-mail inválido ou limite de tentativas alcançadas.');
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      }).catch(() => {
        setEmailError('E-mail inválido ou limite de tentativas alcançadas.');
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Card>
        <CardContent>
          <Box width={400} display='flex' justifyContent='center' alignItems='center' alignContent='center'>
            <ImageListItem>
              <img src="https://i.ibb.co/zScQ2X1/logo.png" alt="Logo MCare"></img>
            </ImageListItem>
          </Box>
          <Box display='flex' flexDirection='column' gap={2} width={400}>
            <Typography variant='h6'>Recuperar Senha</Typography>
            <TextField
              label='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={() => setEmailError('')}
              error={!!emailError}
              helperText={emailError}
              type='email'
              required
              fullWidth>
            </TextField>
          </Box>
        </CardContent>
        <CardActions>
          <Box width='100%' display='flex' justifyContent='center' gap={1}>
            {isLoading ? (
              <IconButton >
                <CircularProgress size={20} style={{ color: '#02255C' }} />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                onClick={handleForgot}
              >
                Enviar
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => navigate('/login')}
            >
              Voltar
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box >
  );
};