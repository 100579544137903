import { useEffect, useMemo, useState } from 'react';
import { IListUser, UserService } from '../../shared/services/api/entity/UserServices';
import { BarFilterTable } from '../../shared/components';
import { LayoutDefault } from '../../shared/layouts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UseDebounce } from '../../shared/hooks';
import { Box, CircularProgress, IconButton, Icon } from '@mui/material';
import { Environment } from '../../shared/environment';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';
import { dataTableStyle, noData, paginationComponentOptions } from '../../shared/utils/datatable/style';
import { useAppThemeContext } from '../../shared/contexts';


export const ListUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = UseDebounce(1000);
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListUser[]>([]);
  const [pending, setPending] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const theme = useAppThemeContext();

  const searchName = useMemo(() => {
    return searchParams.get('name') || '';
  }, [searchParams]);

  const searchCrm = useMemo(() => {
    return searchParams.get('crm') || '';
  }, [searchParams]);

  const page = useMemo(() => {

    return Number(searchParams.get('page') || '5');
  }, [searchParams]);

  const handleGoToTask = (rows: IListUser) => {
    if (rows) {
      navigate(`/user/edit/${rows.username}`);
    } else {
      navigate('/users/new');
    }
  };


  useEffect(() => {
    setPending(true);

    debounce(() => {
      UserService.getAll()
        .then((result) => {
          setPending(false);

          if (result instanceof Error) {
            toast.info('atualizando a pagina para recarregar os cookies.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            if (result.message === 'Erro de conexão.') {
              location.reload();
            }
          } else {
            let newResult = result.data;

            if (searchName) {
              newResult = newResult.filter(el => el.name.toLowerCase().includes(searchName.toLowerCase()));
            }

            if (searchCrm) {
              newResult = newResult.filter(el => el.crm.toLowerCase().includes(searchCrm.toLowerCase()));
            }
            setTotalCount(newResult.length);
            setRows(newResult);
          }
        });
    });
  }, [searchName, searchCrm, page]);



  const HandleDelete = (username: string) => {
    if (confirm('Realmente deseja apagar este registro?')) {

      UserService.deleteById(username)
        .then(result => {
          if (result instanceof Error) {

            alert(result.message);

          } else {
            setRows(oldRows => [
              ...oldRows.filter(oldRow => oldRow.username !== username),
            ]);
            alert('Registro apagado com sucesso!');
          }
        });
    }
  };

  const columns: TableColumn<IListUser>[] = [
    {
      name: 'Ações',
      cell: (rows) =>
        <Box>
          <IconButton size="small" onClick={() => navigate(`/user/edit/${rows.username}`)}>
            <Icon>edit</Icon>
          </IconButton>
        </Box>
    },
    {
      name: 'Médico',
      selector: rows => rows.name,
      sortable: true,
    },
    {
      name: 'CRM',
      selector: rows => rows.crm,
      sortable: true,
    },
    {
      name: 'Email (Login)',
      selector: rows => rows.email,
      sortable: true,
    },
    {
      name: 'Celular',
      selector: rows => rows.email,
      sortable: true,
    },
    {
      name: 'Validação de Email',
      selector: rows => { if (rows.status === 'CONFIRMED') return 'Concluída'; return 'Pendente'; },
      sortable: true,
    },
  ];

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress style={{ color: '#02255C' }} />
      <div>{Environment.LOOKING_RECORD}</div>
    </div>
  );

  return (
    <LayoutDefault title="Lista de Médicos">
      <BarFilterTable
        showInputSearch={true}
        textSearchName={searchName ?? ''}
        textSearchCRM={searchCrm ?? ''}
        changeTextSearchName={text => setSearchParams({ name: text }, { replace: true })}
        changeTextSearchCRM={text => setSearchParams({ crm: text }, { replace: true })}
        showButtonNew={true}
        changeButtonNew={() => navigate('/user/new/')}
        showInputCRM={true}
      />
      <Box minHeight={400}>
        <DataTable
          columns={columns}
          data={rows}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={totalCount}
          progressComponent={<CustomLoader />}
          pointerOnHover
          theme={theme.themeName === 'light' ? 'light' : 'dark'}
          onRowClicked={handleGoToTask}
          customStyles={dataTableStyle}
          noDataComponent={noData.text}
        />
      </Box>
    </LayoutDefault>
  );
};