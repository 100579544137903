import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './interceptors';
import { Environment } from '../../../environment';
import { parseCookies } from 'nookies';


export const setupAPIClient = (ctx = undefined) => {
  const cookies = parseCookies(ctx);
  const Api = axios.create({
    baseURL: Environment.URL_BASE,
    headers: {
      'Authorization': `${cookies['@airbnb-Id-Token']}`,
      'Content-Type': 'application/json',
    }
  });

  const ApiAuth = axios.create({
    baseURL: Environment.URL_AUTH,
    headers: {
      'Authorization': `${cookies['@airbnb-Id-Token']}`,
      'Content-Type': 'application/json',
    }
  });


  Api.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  ApiAuth.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return [Api, ApiAuth];
};