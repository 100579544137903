import { api } from '../..';
import { Environment } from '../../../environment';

export interface IMedicalRecord {
  item: IMedicalRecord;
  born_date: Date;
  evolutions: IEvolutions[];
  cognito_email_professional: string;
  status: string;
  id_research_client: string;
  cpf: string;
  date_created: Date;
  crm_prof: IEvolutions['crm_prof'];
  username_prof_cognito: IEvolutions['username_prof_cognito'];
  id: string;
  symptom_table: ISymptomTable[];
  name: string;
  phone: string;
}

export interface IEvolutions {
  allergies: string;
  crm_prof: string;
  date: string;
  date_created: Date;
  email: string;
  evolution_id: string;
  health_issues: string;
  id_prontuario: string;
  medication: string;
  mobile: string;
  name: string;
  register: string;
  register_date: Date;
  result_test_covid: string;
  symptoms: string;
  symptoms_date: Date;
  tel: string;
  celular: string;
  test_covid: string;
  username_prof_cognito: string;
}

export interface ISymptomTable {
  symptom_table_id: string;
  date_created: string;
  ageusa: string;
  anosmia: string;
  astralgia: string;
  caimbras: string;
  cefaleia_leve: string;
  cefaleia_forte: string;
  congestao_nasal: string;
  diarreia: string;
  dor_abdominal: string;
  dor_costas: string;
  dor_olhos: string;
  dor_torax_peito: string;
  edema: string;
  falta_ar_leve: string;
  falta_ar_media: string;
  formigamento: string;
  fraqueza_astemia: string;
  garganta: string;
  garganta_irritada: string;
  inapetencia: string;
  mialgia: string;
  nauseas: string;
  odinofagia: string;
  otalgia: string;
  pressao_olhos: string;
  prurido: string;
  sinusite: string;
  sudorese: string;
  tontura: string;
  coriza: string;
  vomitos: string;
  sat_oxigenio_m: string;
  sat_oxigenio_t: string;
  sat_oxigenio_n: string;
  sat_oxigenio_moderado_m: string;
  sat_oxigenio_moderado_t: string;
  sat_oxigenio_moderado_n: string;
  temperatura_m: string;
  temperatura_t: string;
  temperatura_n: string;
  temperatura_normal_m: string;
  temperatura_normal_t: string;
  temperatura_normal_n: string;
  temperatura_febre_m: string;
  temperatura_febre_t: string;
  temperatura_febre_n: string;
  temperatura_febre_alta_m: string;
  temperatura_febre_alta_t: string;
  temperatura_febre_alta_n: string;
  temperatura_hipertermia_m: string;
  temperatura_hipertermia_t: string;
  temperatura_hipertermia_n: string;
  temperatura_frio_calor_sudorese: string;
  fisiologia_respiratoria_m: string;
  fisiologia_respiratoria_t: string;
  pronar_m: string;
  pronar_t: string;
  pronar_n: string;
  ivermectina: string;
  azitromicina: string;
  vitamina_d: string;
  vitamina_c: string;
  zinco: string;
  curcuma: string;
}

interface IMedicalRec {
  status: string;
}

export interface IDetaiMedicalRecord {
  born_date: Date;
  evolutions: object;
  cognito_email_professional: string;
  status: string;
  id_research_client: string;
  cpf: string;
  date_created: Date;
  id: string;
  symptom_table: object;
  name: string;
  phone: string;
}

type IMedicalRecordCount = {
  data: IMedicalRecord[];
  totalCount: number;
}

const getAll = async (params = {}): Promise<IMedicalRecordCount | Error> => {

  try {
    // eslint-disable-next-line prefer-const
    let { data, headers } = await api[1].post('/filter-prontuarios', params);

    return {
      data,
      totalCount: Number(headers['x-total-count'] || Environment.LINE_LIMIT),
    };

    return new Error('Erroo ao listar o registro...');

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao listar os registros...');
  }
};

export const MedicalRecordService = {
  getAll,
};