import { useEffect, useState, useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BarFilterTable } from '../../shared/components';
import { LayoutDefault } from '../../shared/layouts';
import { IListUser, UserService } from '../../shared/services/api/entity/UserServices';
import { UseDebounce } from '../../shared/hooks';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { VTextField } from '../../shared/forms';
import { toast } from 'react-toastify';

interface IFormData {
  email: string;
  name: string;
  crm: string;
  password: string;
  passwordConfirm: string;
  phone: string;
}

interface IError {
  err?: string;
}

export const NewUser: React.FC = () => {
  const { username = 'new' } = useParams<'username'>();
  const [rows, setRows] = useState<IListUser>();
  const [error, setError] = useState<IError>();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { debounce } = UseDebounce(1000);
  const theme = useTheme();

  const navigate = useNavigate();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSave = async (data: IFormData) => {
    setIsLoading(true);

    if (data.password === data.passwordConfirm) {
      await UserService.create(data).then((res) => {
        if (res instanceof Error) {
          if (res.message === 'This username already exists.') {
            const err = 'Email já cadastrado.';
            setError({ err });

            setIsLoading(false);

            toast.error('Email já cadastrado.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
        } else {
          setIsLoading(false);

          toast.success('Registro cadastrado com sucesso.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });

          setTimeout(() => {
            location.reload();
          }, 5000);
        }
      });
    } else {
      setIsLoading(false);

      toast.error('As senhas não conferem.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleDelete = () => {
    console.log('delete');
  };

  useEffect(() => {
    debounce(() => {
      UserService.getAll()
        .then((resAll) => {
          if (resAll instanceof Error) {
            console.log('error');

          } else {
            resAll.data.map((res) => {
              setRows(res);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, []);

  return (
    <LayoutDefault title="Editar Registro">
      <BarFilterTable
        showInputSearch={false}
        loadingSearch={false}
        showButtonFilter={false}
        showButtonBack={true}
        showButtonNew={true}
        showButtonSave={true}
        showButtonSaveLoading={isLoading}
        showButtonDelete={false}
        changeButtonBack={() => navigate('/users/')}
        changeButtonNew={() => navigate('/user/new')}
        changeButtonSave={() => formRef.current?.submitForm()}
        changeButtonDelete={() => handleDelete}
        showInputSelect={false}
        showInputDate={false}
      />
      <Form ref={formRef} onSubmit={handleSave}>
        <Box border="1px solid #c4c4c4" display="flex" gap={1} alignContent="center" alignItems="center" justifyContent="center" flexDirection="column" padding={10}>
          <Box sx={{ width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <VTextField
              name="email"
              label="Email (Login)"
              error={!!error?.err}
              fullWidth
              helperText={error?.err}
              variant="filled"
            />
          </Box>
          <Box sx={{ width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <VTextField
              name="name"
              label="Name"
              fullWidth
              value={rows?.name}
              variant="filled"
            />
          </Box>
          <Box display="flex" gap={1} sx={{ flexDirection: smDown || mdDown || lgDown ? 'column' : '', width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="crm"
                label="CRM"
                fullWidth
                variant="filled"
              />
            </Box>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="phone"
                label="Celular"
                type="tel"
                fullWidth
                variant="filled"
              />
            </Box>
          </Box>
          <Box display="flex" gap={1} sx={{ flexDirection: smDown || mdDown || lgDown ? 'column' : '', width: smDown || mdDown || lgDown ? '205px' : '40%' }}>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="password"
                label="Senha"
                type="password"
                fullWidth
                variant="filled"
              />
            </Box>
            <Box width={smDown || mdDown || lgDown ? 205 : 215}>
              <VTextField
                name="passwordConfirm"
                label="Confirmar Senha"
                type="password"
                fullWidth
                variant="filled"
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </LayoutDefault >
  );
};