import { useEffect, useMemo, useState } from 'react';
import { BarFilterTable } from '../../shared/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { Grid, Box, CircularProgress } from '@mui/material';
import { IMedicalRecord, MedicalRecordService } from '../../shared/services/api/entity/MedicalRecords';
import moment from 'moment';
import 'moment/locale/pt-br';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Environment } from '../../shared/environment';
import { dataTableStyle, noData, paginationComponentOptions } from '../../shared/utils/datatable/style';
import { useAppThemeContext } from '../../shared/contexts';



export const MedicalRecord = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useAppThemeContext();

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });
  const [rows, setRows] = useState<IMedicalRecord[]>([]);
  const handleSearch = async () => {
    await MedicalRecordService.getAll()
      .then((response) => {
        if (response instanceof Error) {
          console.log(response);
          if (response.message === 'Erro de conexão.') {
            location.reload();
          }
        } else {
          let result = response.data;

          if (result.length) {
            if (search) {
              result = result.filter(el => el.name.toLowerCase().includes(search.toLowerCase()));

            }
            if (filter.startDate && filter.endDate) {
              const startDate = moment(filter.startDate, 'dd/MM/yyyy');
              const endDate = moment(filter.endDate, 'dd/MM/yyyy') + 'T23:59:59';
              result = result.filter(el =>
                moment(el.date_created, 'dd/MM/yyyy') > startDate && moment(el.date_created, 'dd/MM/yyyy') < moment(endDate, 'dd/MM/yyyy')
              );
            }
            setRows(result);
            setTotalCount(result.length);
          }
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  const columns: TableColumn<IMedicalRecord>[] = [
    {
      name: 'Nome',
      selector: rows => rows.name,
    },
    {
      name: 'Data',
      selector: rows => moment(rows.date_created).format('DD/MM/YYYY'),
    },
  ];

  const goToEvolution = (rows: IMedicalRecord) => {
    navigate(`/prontuario/evolution/${rows.id}`);
  };

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress style={{ color: '#02255C' }} />
      <div>{Environment.LOOKING_RECORD}</div>
    </div>
  );



  return (
    <LayoutDefault title="Lista de pesquisas respondidas pelos pacientes">
      <BarFilterTable
        showInputSearch={true}
        textSearchName={search ?? ''}
        changeTextSearchName={text => setSearchParams({ search: text, page: '1' }, { replace: true })}
        changeButtonNew={() => navigate('/user/new/')}
        showButtonNew={true}
        showInputDate={true}
      />
      <Grid minHeight={400}>
        <DataTable
          columns={columns}
          data={rows}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={totalCount}
          pointerOnHover
          progressComponent={<CustomLoader />}
          theme={theme.themeName === 'light' ? 'light' : 'dark'}
          onRowClicked={(row) => goToEvolution(row)}
          customStyles={dataTableStyle}
          noDataComponent={noData.text}
        />
      </Grid>
    </LayoutDefault>
  );
};