import { Box, Typography, Button } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useEffect, useState } from 'react';
import { LayoutDefault } from '../../../shared/layouts';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { BarFilterTable } from '../../../shared/components';
import { useNavigate, useParams } from 'react-router-dom';
import { dataTableStyle, noData, paginationComponentOptions } from '../../../shared/utils/datatable/style';
import { IEvolutions, IMedicalRecord, ISymptomTable, MedicalRecordService } from '../../../shared/services/api/entity/MedicalRecords';
import { useAppThemeContext } from '../../../shared/contexts';

type IUseParams = {
  id: string;
}

export const Evolution = () => {
  const navigate = useNavigate();
  const theme = useAppThemeContext();
  const [rowsEvo, setRowsEvo] = useState<IEvolutions[]>([]);
  const [rowsSymp, setRowsSymp] = useState<ISymptomTable[]>([]);
  const [rowsAll, setRowsAll] = useState<IMedicalRecord[]>([]);
  const { id } = useParams<IUseParams>();
  const handleSearch = async () => {
    await MedicalRecordService.getAll({ id: id })
      .then((response) => {
        if (response instanceof Error) {
          if (response.message === 'Erro de conexão.') {
            location.reload();
          }
        } else {
          const result = response.data;

          setRowsAll(result);

        }
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const columns: TableColumn<any>[] = [
    {
      name: 'Médico',
      selector: rowsEvo => rowsEvo.username_prof_cognito,
    },
    {
      name: 'CRM',
      selector: rowsEvo => rowsEvo.crm_prof,
    },
    {
      name: 'Data',
      selector: rowsEvo => moment(rowsEvo.date_created).format('DD/MM/YYYY'),
    },
  ];

  const handleGoToEvolution = (rowsEvo: IEvolutions) => {
    navigate(`/prontuario/evolution/${id}/edit/${rowsEvo.evolution_id}`);
  };

  const handleGoToSymptom = (rowsSymp: ISymptomTable) => {
    navigate(`/prontuario/symptom/${id}/edit/${rowsSymp.symptom_table_id}`);
  };

  const styleCustom = {
    fontSize: '1.2em',
    fontWeight: 400,
    lineHeight: 1.5
  };

  const styleTypo = {
    fontWeight: 700,
    marginLeft: 10,
    color: '#043b87'
  };

  return (
    <LayoutDefault title="Prontuário do Paciente">
      <BarFilterTable
        changeButtonBack={() => navigate('/prontuarios')}
        showButtonBack={true}
      />
      <Box width="100%" height={100}>
        <Box display="flex" padding={1}>
          <Box>
            <Typography sx={styleCustom}> Paciente:</Typography>
          </Box>
          <Box>
            <Typography sx={styleCustom} style={styleTypo}>{rowsAll[0]?.name}</Typography>
          </Box>
        </Box>
        <Box display="flex" padding={1}>
          <Box>
            <Typography sx={styleCustom}>Telefone:</Typography>
          </Box>
          <Box>
            <Typography sx={styleCustom} style={styleTypo}>{rowsAll[0]?.phone}</Typography>
          </Box>
          <Box sx={{ marginLeft: 4 }}>
            <Typography sx={styleCustom}>Idade:</Typography>
          </Box>
          <Box>
            <Typography sx={styleCustom} style={styleTypo}>{rowsAll && Math.floor(moment(new Date()).diff(moment(rowsAll[0]?.born_date, 'YYYY'), 'years', true)) + ' anos'}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" width="100%" justifyContent="center" gap={1}>
        <Box minHeight={200} width="50%" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
          <DataTable
            title="Evolução"
            columns={columns}
            data={rowsAll[0]?.evolutions}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            customStyles={dataTableStyle}
            noDataComponent={noData.text}
            theme={theme.themeName === 'light' ? 'light' : 'dark'}
            actions={
              <Button startIcon={<ControlPointIcon />} variant="contained" color="primary" onClick={() => navigate(`/prontuario/evolution/${id}/new`)}>
                Nova Evolução
              </Button>
            }
            pointerOnHover
            onRowClicked={handleGoToEvolution}
          />
        </Box>
        <Box minHeight={200} width="50%" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
          <DataTable
            title="Sintomas"
            columns={columns}
            data={rowsAll[0]?.symptom_table}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            customStyles={dataTableStyle}
            noDataComponent={noData.text}
            theme={theme.themeName === 'light' ? 'light' : 'dark'}
            actions={
              <Button startIcon={<ControlPointIcon />} variant="contained" color="primary" onClick={() => navigate(`/prontuario/symptom/${id}/new`)}>
                Novo Sintoma
              </Button>
            }
            pointerOnHover
            onRowClicked={handleGoToSymptom}
          />
        </Box>
      </Box>
    </LayoutDefault >
  );
};