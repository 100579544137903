import { Grid, Container, TextField, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useNavigate, useParams } from 'react-router-dom';
import { BarFilterTable } from '../../../shared/components';
import { VTextField } from '../../../shared/forms';
import { LayoutDefault } from '../../../shared/layouts';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { api } from '../../../shared/services';

interface IEvolutions {
  allergies: string;
  crm_prof: string;
  date: string;
  date_created: Date;
  email: string;
  health_issues: string;
  medication: string;
  mobile: string;
  name: string;
  register: string;
  register_date: Date;
  result_test_covid: string;
  symptoms: string;
  symptoms_date: Date;
  tel: string;
  celular: string;
  test_covid: string;
  username_prof_cognito: string;
}

type IUseParams = {
  id: string;
  evolution_id: string;
}


export const NewEvolution = () => {
  const navigate = useNavigate();
  const { id, evolution_id } = useParams<IUseParams>();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<IEvolutions[]>([]);

  const handleSearch = async () => {
    await api[1].post('/filter-prontuarios', { id: id }).then((response) => {

      setRows(response.data[0]);

      formRef.current?.setData(response.data[0]);

    }).catch((err) => {
      console.log(err);
      if (err.message === 'Erro de conexão.') { 
        location.reload();
      }
    });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSave = async (data: IEvolutions) => {
    const result = {
      id_prontuario: id,
      evolution_id,
      ...data
    };

    setIsLoading(true);
    await api[1].post('/new-medical-evolution', result)
      .then(() => {
        toast.success('Novos sintomas adicionado com sucesso.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        setTimeout(() => {
          setIsLoading(false);
          navigate(`/prontuario/evolution/${id}`);
        }, 5000);

      }).catch((error) => {

        toast.error('Error: Verifique os campos e tente novamente.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <LayoutDefault title="Evolução">
      <BarFilterTable
        showButtonBack={true}
        showButtonSave={true}
        showButtonSaveLoading={isLoading}
        changeButtonSave={() => formRef.current?.submitForm()}
        changeButtonBack={() => navigate(`/prontuario/evolution/${id}`)}
      />
      <Container maxWidth="lg">
        <Form ref={formRef} onSubmit={handleSave}>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={9} xs={12}>
              <VTextField
                label='Nome'
                name="name"
                fullWidth
                size="small"
                variant="filled"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                label='Data de Atentimento'
                type="date"
                placeholder='data de atentimento'
                name="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={9} xs={12}>
              <VTextField
                label='Registro'
                placeholder='Registro'
                name="register"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                label='Data de Registro'
                type="date"
                placeholder='Data de registro'
                name='register_date'
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={6} xs={12}>
              <VTextField
                label='Email'
                name="email"
                placeholder='email'
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                name="celular"
                label='Celular'
                placeholder='Data de registro'
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                name="tel"
                label='Telefone'
                placeholder='telefone'
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='filled'
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={9} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Teste de COVID-19</FormLabel>
                <RadioGroup aria-label="test_covid" name="test_covid" value={rows[0]?.test_covid}>
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Sim" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={5}
                label="Resultado"
                name="result_test_covid"
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={9} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Alergias</FormLabel>
                <RadioGroup aria-label="allergies" name="allergies" value={rows[0]?.allergies}>
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Sim" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={5}
                label="Medicação"
                name="medication"
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={5}>
            <Grid item md={6} xs={12}>
              <VTextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Problemas de saúde"
                name="health_issues"
                defaultValue="1"
                variant="filled"
                select
                SelectProps={{ native: true }}
              >
                <option value="none">Não</option>
                <option value="1">Sim</option>
              </VTextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Sintomas"
                name="symptoms"
                defaultValue="none"
                variant="filled"
                select
                SelectProps={{ native: true }}
              >
                <option value="none">Não</option>
                <option value="1">Sim</option>
              </VTextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <VTextField
                size="small"
                type="date"
                fullWidth
                label="Data de início dos sintomas"
                name="symptoms_date"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Form>
      </Container>
    </LayoutDefault>

  );
};