import { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext, useDrawerContext } from '../shared/contexts';
import { Email, Patient, MedicalRecord, ListUsers, EditUser, NewUser } from '../pages/';
import { Evolution } from '../pages/medical_record/evolution/Evolution';
import { EditEvolution } from '../pages/medical_record/evolution/EditEvolution';
import { EditSymptom } from '../pages/medical_record/symptom/EditSymptom';
import { PatientForm } from '../pages/patients/patient_form/PatientForm';
import { NewEvolution } from '../pages/medical_record/evolution/NewEvolution';
import { NewSymptom } from '../pages/medical_record/symptom/NewSymptom';

export const AuthRoutes = () => {
  const { setDrawerOption } = useDrawerContext();

  useEffect(() => {
    setDrawerOption([
      {
        icon: 'mail',
        path: '/emails',
        label: 'Envio de Pesquisa COVID'
      },
      {
        icon: 'list_alt',
        path: '/patients',
        label: 'Pesquisas de Pacientes'
      },
      {
        icon: 'assignment_turned_in',
        path: '/prontuarios',
        label: 'Prontuários'
      }
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/emails" element={<Email />}
      />
      <Route path="/patients" element={<Patient />} />
      <Route path="/patient/:id" element={<PatientForm />} />

      <Route path="/prontuarios" element={<MedicalRecord />} />
      <Route path="/prontuario/evolution/:id" element={<Evolution />} />
      <Route path="/prontuario/evolution/:id/new" element={<NewEvolution />} />
      <Route path="/prontuario/evolution/:id/edit/:evolution_id" element={<EditEvolution />} />

      <Route path="/prontuario/symptom/:id/new" element={<NewSymptom />} />
      <Route path="/prontuario/symptom/:id/edit/:id_symptom" element={<EditSymptom />} />


      <Route path="/users" element={<ListUsers />} />
      <Route path="/user/edit/:username" element={<EditUser />} />
      <Route path="/user/new/" element={<NewUser />} />

      <Route path="*" element={<Navigate to={'/emails'} />} />
    </Routes >
  );
};