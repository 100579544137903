import { createTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';

export const DarkTheme = createTheme({
  palette: {
    primary: {
      main: blue[700],
      dark: blue[800],
      light: blue[500],
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[700],
      dark: grey[800],
      light: grey[500],
      contrastText: '#ffffff',
    },
    background: {
      default: '#202124',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: grey[500],
    },
  },
  typography: {
    allVariants: {
      color: 'white'
    },
  }
});