import { useContext, useState, FormEvent } from 'react';
import { Box, Button, Card, CardActions, CardContent, IconButton, Link, ImageListItem, TextField, Typography, CircularProgress } from '@mui/material';
import { AuthContext } from '../../contexts';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface ILoginProps {
  children: React.ReactNode;
}

export const Login = () => {

  const { signIn } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    const data = {
      username: email,
      password,
    };

    await signIn(data)
      .then(() => {
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MCare
          </Typography>
        </Toolbar>
      </AppBar>
      <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center' sx={{ backgroundImage: 'url(https://i.ibb.co/nbFJLCj/bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed' }}>
        <Card sx={{ marginTop: -10, width: 350 }}>
          <CardContent>
            <Box width={'100%'} display='flex' justifyContent='center' alignItems='center' alignContent='center'>
              <ImageListItem>
                <img src="https://i.ibb.co/zScQ2X1/logo.png" style={{ width: '100%' }} alt="Logo MCare"></img>
              </ImageListItem>
            </Box>
            <Box display='flex' flexDirection='column' gap={2} width={'100%'}>
              <Typography variant='h6'>Logar-se</Typography>
              <TextField
                label='Email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyDown={() => setEmailError('')}
                error={!!emailError} helperText={emailError}
                type='email'
                required
                fullWidth>
              </TextField>
              <TextField
                label='Senha'
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={() => setPasswordError('')}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
              >
              </TextField>
            </Box>
            <Box display="flex">
              <Box width="50%" display="flex" justifyContent="start" alignItems="start">
                <Box padding={1}>
                  <Link href="/forgot-password"><Typography>Esqueceu a senha?</Typography></Link>
                </Box>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            <Box width='100%' display='flex' justifyContent='center'>
              {isLoading ? (
                <IconButton >
                  <CircularProgress size={20} style={{ color: '#02255C' }} />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleLogin}
                >
                  Entrar
                </Button>
              )}
            </Box>
          </CardActions>
        </Card>
      </Box>
    </Box >
  );
};