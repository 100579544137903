import { Environment } from '../../../environment';
import { api } from '../..';


export interface IListUser {
  username: string;
  name: string;
  email: string;
  crm: string;
  phone: string;
  enabled: boolean;
  status: string;
}

export interface IDetailUser {
  username: string;
  name: string;
  email: string;
  crm: string;
  phone: string;
  enabled: boolean;
  status: string;
}

export type TUsersCount = {
  data: IListUser[];
  totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TUsersCount | Error> => {
  try {
    const urlRelative = `/list-users?_page=${page}&_limit=${Environment.LINE_LIMIT}&name_like=${filter}`;

    const { data, headers } = await api[1].post(urlRelative);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LINE_LIMIT),
      };
    }

    return new Error('Erroo ao listar o registro...');

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao listar os registros...');
  }
};

const create = async (response: Omit<IDetailUser, 'username' | 'status' | 'enabled'>): Promise<string | boolean | Error> => {
  try {
    const { data } = await api[0].post<IDetailUser>('/signup', response);

    if (data) {
      return data.username;
    }

    return new Error('Erro ao criar o registro...');

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao criar o registro...');
  }
};

const updateByUsername = async (response: Omit<IDetailUser, 'email' | 'status' | 'enabled'>): Promise<string | boolean | Error> => {
  try {
    const { data } = await api[1].post('/edit-user', response);

    if (data) {
      return data;
    }

    return new Error('Erro ao criar o registro...');

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao atualizar o registro...');
  }
};

const deleteById = async (username: string): Promise<void | Error> => {
  try {
    await api[0].delete(`/users/${username}`);

  } catch (e) {
    console.error(e);

    return new Error((e as { message: string }).message || 'Erro ao deletar o registro...');
  }
};

export const UserService = {
  getAll,
  create,
  updateByUsername,
  deleteById,
};